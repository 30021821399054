<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="form.titulo"
            placeholder="Título"
            prepend-inner-icon="subtitles"
            dark
            filled
            background-color="rgba(0,0,0,.5)"
            hide-details
            :rules="[v => !!v || 'Campo obrigatório!']"
          />
        </v-col>

        <v-col cols="12">
          <v-card
            dark
            color="transparent"
          >
            <v-card-text class="pa-4">
              <v-row>
                <v-col cols="8">
                  <v-select
                    v-model="form.tipo"
                    :items="[
                      { text: 'Aprovação', value: 'APROVACAO' },
                      { text: 'Suspensão', value: 'SUSPENSAO' },
                      { text: 'Desvinculação', value: 'DESVINCULACAO' },
                      { text: 'Outros', value: 'OUTROS' }
                    ]"
                    label="Tipo"
                    item-value="value"
                    item-text="text"
                    dark
                    outlined
                    background-color="rgba(0,0,0,.2)"
                    hide-details
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                </v-col>

                <v-col cols="4">
                  <v-select
                    v-model="form.todos"
                    :items="[
                      { text:'Sim', value: true },
                      { text:'Não', value: false }
                    ]"
                    label="Enviar para todos"
                    item-value="value"
                    item-text="text"
                    dark
                    outlined
                    background-color="rgba(0,0,0,.2)"
                    hide-details
                  />
                </v-col>

                <v-col
                  cols="12"
                >
                  <v-combobox
                    v-model="form.emails"
                    label="Insira os e-mails"
                    prepend-inner-icon="subtitles"
                    multiple
                    chips
                    clearable
                    dark
                    outlined
                    hide-details
                    append-icon=""
                    @keydown.enter.prevent="addEmail"
                  />
                </v-col>

                <v-col cols="12">
                  <v-btn-toggle
                    dark
                    :style="{ background: 'rgba(0,0,0,.2)', borderRadius: '4px' }"
                    group
                    @change="onFieldClick"
                  >
                    <v-btn value="#nome#">
                      NOME
                    </v-btn>
                    <v-btn value="#cnpj_cpf#">
                      CNPJ/CPF
                    </v-btn>
                    <v-btn value="#rg#">
                      RG
                    </v-btn>
                    <v-btn value="#municipio#">
                      MUNICÍPIO
                    </v-btn>
                    <v-btn value="#uf#">
                      UF
                    </v-btn>
                    <v-btn value="#bairro#">
                      BAIRRO
                    </v-btn>
                    <v-btn value="#endereco#">
                      ENDEREÇO
                    </v-btn>
                    <v-btn value="#cidasc#">
                      CIDASC/IMA
                    </v-btn>
                    <v-btn value="#inscricao_estadual#">
                      Inscrição estadual
                    </v-btn>
                  </v-btn-toggle>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    ref="text"
                    v-model="form.texto"
                    label="Texto"
                    dark
                    outlined
                    background-color="rgba(0,0,0,.2)"
                    hide-details
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green"
          class="mr-5"
          v-on="on"
          @click="save()"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>
      <span>Salvar</span>
    </v-tooltip>

    <v-overlay :value="loading || saving">
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  props: {
    editId: String,
  },

  data() {
    return {
      loading: false,
      saving: false,
      form: {
        titulo: '',
        tipo: 'OUTROS',
        texto: '',
        emails: [],
        todos: false,
      },
    }
  },

  mounted() {
    if (this.editId) {
      this.load()
    }
  },

  methods: {
    async load() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/registrations/document/${this.editId}`);

        this.form = {
          titulo: data.titulo,
          tipo: data.tipo,
          texto: data.texto,
          emails: data.emails ? data.emails : [],
          todos: data.todos ? data.todos : false,
        };

      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar o documento!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }
        this.saving = true;

        const data = this.editId ? await this.update(this.form) : await this.store(this.form);

        const editId = data.id

        this.$emit("update:editId", editId);
        this.$emit('save', editId);
        this.$snotify.success("Documento salvo com sucesso!", "Sucesso");
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar o documento!", "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/registrations/document`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/registrations/document/${this.editId}`, payload);
      return data;
    },

    onFieldClick(value) {
      const textarea = this.$refs.text.$el.querySelector('textarea');
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = this.form.texto;

      this.form.texto = text.substring(0, start) + value + text.substring(end, text.length);
    },
    addEmail(event) {
      const email = event.target.value.trim();
      if (email && !this.form.emails.includes(email)) {
        this.form.emails.push(email);
        event.target.value = '';
      } else {
        this.$snotify.error("E-mail já adicionado ou inválido!", "Atenção");
      }
    },

  }
}
</script>
