<template>
  <div>
    <v-timeline-item
      color="blue darken-1"
      fill-dot
      right
      large
    >
      <template #icon>
        <span
          class="text-center"
          style="color: white; font-size: 12px !important;"
        >
          {{ formatDate(item.data_hora || date, 'HH:mm') }}
        </span>
      </template>

      <v-card class="elevation-2">
        <v-card-title
          class="text-overline pa-0 pt-1 pl-2 pr-2 pb-2"
          style="font-size: 12px !important;"
        >
          <span>
            <b>Tanque {{ item.origem }}</b>
            <v-icon
              small
              class="mx-2 mb-1"
              color="blue"
            >
              redo
            </v-icon>
            <b>Tanque {{ item.destino }} </b>
            |
            {{ formatDate(item.data_hora || date, 'DD/MM HH:mm') }}
          </span>
        </v-card-title>

        <v-card-text
          class="pb-2"
        >
          <v-row class="text-caption pl-2 pr-2">
            <v-col
              cols="12"
              class="text-left pa-0"
            >
              Volume: {{ item.volume }} L
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {

  props: {
    item: Object,
    date: String,
  },

  methods: {
    formatDate: (value, opts) => moment(value).format(opts),
  },

}
</script>
