import { ref } from 'vue'
import moment from 'moment'
import axios from '@/Support/Resources/axios-instance.js'

import { useUtils } from '@/Support/Composables/utils.js'

export const useLotNumber = () => {

  const { progressBar, notify } = useUtils()

  const generatedLotNumber = ref(null)
  const seq = ref(0)
  const unitLetter = ref(null)

  const resetLotNumber = () => {
    generatedLotNumber.value = null
    seq.value = 0
    unitLetter.value = null
  }

  const getItemSequence = async (itemId) => {
    try {
      progressBar.loading()

      const { data } = await axios.get(`/stock/lot-sequence`, {
        params: {
          id_item: itemId
        }
      })

      seq.value = data.sequencial
      unitLetter.value = data.letra_unidade

    } catch (e) {
      const message = e?.response?.data?.message || 'Erro ao gerar lote'
      notify.error(message, 'Atenção')
      console.warn(e)
    } finally {
      progressBar.hide()
    }
  }

  const getLotNumber = async (itemId, lotFormat, date) => {
    if (!lotFormat || !date) {
      resetLotNumber()
      return null
    }

    const fields = lotFormat.split('-')
    const ts = moment(date)

    if ((fields.includes('SEQ') && !seq.value) || (fields.includes('LET') && !unitLetter.value)) {
      await getItemSequence(itemId)
    }

    const mapper = {
      'YYYY': ts.format('YYYY'),
      'YY': ts.format('YY'),
      'MM': ts.format('MM'),
      'DD': ts.format('DD'),
      'LET': unitLetter.value,
      'SEQ': seq.value.toString()
    }

    generatedLotNumber.value = fields.map(item => mapper[item]).join('')

    return generatedLotNumber.value
  }

  return {
    getLotNumber,
    resetLotNumber,
    generatedLotNumber,
  }
}
