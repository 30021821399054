<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Transferência de Unidade
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
      >
        <v-tab href="#history-tab">
          Histórico
        </v-tab>

        <v-tab
          href="#transfer-tab"
          :disabled="tab !== 'transfer-tab'"
        >
          Transferir
        </v-tab>

        <v-tab-item value="history-tab">
          <unit-change-history-tab
            v-if="tab === 'history-tab'"
            @transfer="onTransfer"
          />
        </v-tab-item>

        <v-tab-item value="transfer-tab">
          <unit-change-tab
            v-if="tab === 'transfer-tab'"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref } from 'vue'
import UnitChangeHistoryTab from '@/Domains/Producer/UnitChange/Views/UnitChangeHistory.vue'
import UnitChangeTab from '@/Domains/Producer/UnitChange/Views/UnitChange.vue'

const tab = ref('history-tab')

const onTransfer = () => {
  tab.value = 'transfer-tab';
}
</script>
