<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Histórico de Coletas
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <v-container>
      <v-card
        class="v-tamanho"
        color="transparent"
        dark
      >
        <v-card-title class="py-1 d-block">
          <v-row>
            <v-col md="3">
              <person-autocomplete-filter
                v-model="filters.producer"
                label="Produtor"
                type="PRODUCER"
                show-groupings
                class="flex-grow-1"
                @change="loadCollects"
              />
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-title>

        <data-table
          ref="report"
          dark
          :items="items"
          :headers="headers"
          name="Histórico de Coleta"
          item-key="id_coleta"
          :show-expand="showExpand"
          group-by="nome_laticinio"
          class="elevation-1"
        >
          <template #[`item.nome_produtor`]="{ item }">
            {{ item.nome_produtor }}
            <v-icon
              v-if="item.id_condominio || item.tipo_condominio"
              color="rgba(255, 255, 255, 0.7)"
            >
              groups
            </v-icon>
          </template>

          <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
            <v-btn
              v-if="item.condominio?.length > 1"
              icon
              link
              transition="fab-transition"
              @click="expand(!isExpanded)"
            >
              <v-icon>
                {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </v-icon>
            </v-btn>
          </template>

          <template #expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0 text-center"
            >
              <v-data-table
                :headers="headersGrouping"
                :items="item.condominio"
                :items-per-page="-1"
                dense
                hide-default-footer
                class="elevation-1 ma-3"
              />
            </td>
          </template>

          <template #[`footer.prepend`]>
            <div class="text-caption text-left ml-4">
              Volume Total (L)
              <v-chip
                small
                class="mx-2 mb-0"
              >
                {{ formatNumber(volumeTotal) }}
              </v-chip>
            </div>
          </template>
          <template #[`item.fotos`]="{ value }">
            <v-btn
              v-if="value.length"
              icon
              @click="openGallery(value)"
            >
              <v-icon>image</v-icon>
            </v-btn>
          </template>
          <template #[`item.logs`]="{ item }">
            <v-btn
              v-if="item.logs.length > 0"
              icon
              @click.stop="showCollectLog(item)"
            >
              <v-icon>
                info
              </v-icon>
            </v-btn>
          </template>
        </data-table>
      </v-card>
    </v-container>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-dialog
      v-model="photoDialog.show"
      height="600"
      max-width="650"
    >
      <v-carousel style="text-align: center;">
        <v-carousel-item
          v-for="(foto, i) in photoDialog.pictures"
          :key="i"
        >
          <img
            :src="foto"
            height="100%"
          >
        </v-carousel-item>
      </v-carousel>
    </v-dialog>

    <collect-log-dialog
      ref="collectLogDialog"
    />

    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </v-container>
</template>

<script>
import moment from 'moment-timezone';
import CollectLogDialog from '@/Domains/Itineraries/Components/CollectLogDialog.vue';
import PersonAutocompleteFilter from '@/Support/Components/Filters/PersonAutocompleteFilter.vue';

export default {
  components: {
    CollectLogDialog,
    PersonAutocompleteFilter,
  },

  data() {
    return {
      loading: false,

      filters: {
        producer: {},
      },

      headers: [
        { text: 'Laticínio', value: 'nome_laticinio', align: 'center' },
        { text: 'Rota de Coleta', value: 'nome_rota', align: 'center' },
        { text: 'Data Coleta', value: 'data', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        { text: 'Hora', value: 'hora_saida', align: 'center' },
        { text: 'Efetivação', value: 'data_hora_descarga', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm') },
        { text: 'Motorista', value: 'nome_usuario_coleta', align: 'center' },
        { text: 'Placa', value: 'placa', align: 'center' },
        { text: 'Temperatura', value: 'temperatura', align: 'center', formatter: value => this.formatNumber(value), mask: '#,##0.0' },
        { text: 'Tanque 1', value: 'tanque1', align: 'center', formatter: value => this.formatNumber(value), mask: '#,##0' },
        { text: 'Tanque 2', value: 'tanque2', align: 'center', formatter: value => this.formatNumber(value), mask: '#,##0' },
        { text: 'Tanque 3', value: 'tanque3', align: 'center', formatter: value => this.formatNumber(value), mask: '#,##0' },
        { text: 'Tanque 4', value: 'tanque4', align: 'center', formatter: value => this.formatNumber(value), mask: '#,##0' },
        { text: 'Tanque 5', value: 'tanque5', align: 'center', formatter: value => this.formatNumber(value), mask: '#,##0' },
        { text: 'Tanque 6', value: 'tanque6', align: 'center', formatter: value => this.formatNumber(value), mask: '#,##0' },
        { text: 'Tanque 7', value: 'tanque7', align: 'center', formatter: value => this.formatNumber(value), mask: '#,##0' },
        { text: 'Total (L)', value: 'quantidade_coleta', align: 'center', formatter: value => this.formatNumber(value), mask: '#,##0' },
        { text: 'Número Amostra', value: 'numero_amostra', align: 'center' },
        { text: 'Alizarol', value: 'alizarol', align: 'center' },
        { text: 'Observações', value: 'observacao', align: 'start' },
        { text: 'Latitude', value: 'latitude', align: 'center', width: 30, show: false },
        { text: 'Longitude', value: 'longitude', align: 'center', width: 30, show: false },
        { text: 'Fotos', value: 'fotos', align: 'end', formatter: value =>  value.length > 0 ? 'Sim' : 'Não' },
        { text: '', altText: 'Logs', value: 'logs', width: 50, sortable: false },
      ],

      items: [],

      photoDialog: {
        show: false,
        pictures: [],
      },

      headersGrouping: [
        { text: 'Código', value: 'codigo_laticinio', align: 'center' },
        { text: 'Produtor', value: 'nome', align: 'start'  },
        { text: 'Volume (L)', value: 'volume', align: 'center' },
      ],

      showExpand: false,
    };
  },

  computed: {
    volumeTotal() {
      return this.items.reduce((acc, item) => (acc + parseInt(item.quantidade_coleta)), 0);
    },
  },

  methods: {
    async loadCollects() {
      this.items = [];

      if (!this.filters.producer.id) {
        return;
      }

      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/coleta/historico-coleta`, {
          id_produtor: this.filters.producer.id,
        });

        this.items = data.map((item) => {
          const fotos =  JSON.parse(item.url_coleta) || [];
          const resfriadores = JSON.parse(item.resfriadores) || [];
          const condominio = JSON.parse(item.condominio) || [];

          resfriadores.forEach(e => {
            if (e.url_regua) {
              fotos.push(e.url_regua);
            }
            if (e.url_temperatura) {
              fotos.push(e.url_temperatura);
            }
          });

          return {
            ...item,
            fotos: fotos,
            condominio,
            logs: (item.logs ? JSON.parse(item.logs) : []).map(log => ({
              type: log.tipo,
              previous: log.anterior,
              new: log.novo,
              userName: log.nome_pessoa,
              updatedAt: log.data_hora,
            })),
          }
        })

        this.showExpand = this.items.some(item => item.condominio?.length > 1);
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    openGallery(picture) {
      this.photoDialog.pictures = picture;
      this.photoDialog.show = true;
    },

    getReportTitle() {
      const { name } = this.filters.producer;
      return `Histórico de Coletas - ${name}`;
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs.report.exportExcel(null, filename);
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title, true);
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? '' : moment(value).format(format),

    showCollectLog(collect) {
      return this.$refs.collectLogDialog.show(collect);
    },
  },
}
</script>
