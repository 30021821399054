<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="800px"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">{{ form.customer?.name || title }}</span>
          <v-spacer />
          <v-btn
            icon
            small
            depressed
            @click="close()"
          >
            <v-icon small>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle v-if="form.customer?.name">
          {{ title }}
        </v-card-subtitle>

        <v-card-text>
          <v-form
            v-if="dialog"
            ref="formRef"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col
                class="pt-0"
              >
                <person-autocomplete-filter
                  v-model="form.customer"
                  label="Cliente *"
                  type="CUSTOMER"
                  prepend-inner-icon="person_outline"
                  placeholder=" "
                  :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
                  :hide-details="false"
                  :filled="false"
                />
              </v-col>

              <v-col
                cols="12"
                md="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="form.code"
                  label="Código Pedido"
                  icon="subtitles"
                />
              </v-col>

              <v-col
                cols="12"
                md="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="form.invoiceNumber"
                  label="Nº Nota"
                  icon="subtitles"
                />
              </v-col>

              <v-col
                v-if="!hideDeliveryDate"
                cols="12"
                md="3"
                class="pt-0"
              >
                <date-time-text-field
                  v-model="form.dateTime"
                  label="Data Hora Entrega"
                  icon="access_time"
                  input-format="YYYY-MM-DD HH:mm"
                  manual
                />
              </v-col>

              <v-col
                cols="12"
                class="pt-0"
              >
                <v-textarea
                  v-model="form.notes"
                  label="Observação"
                  rows="2"
                  auto-grow
                />
              </v-col>
            </v-row>

            <v-tabs>
              <v-tab>
                Produtos
              </v-tab>
              <v-tab-item>
                <template
                  v-for="(item, idx) in form.products"
                >
                  <v-row
                    :key="idx"
                    style="border-bottom: 1px #eee solid;"
                  >
                    <v-col
                      class="py-0 d-flex flex-column justify-center"
                      style="max-width: 49px;"
                    >
                      <div>
                        <v-icon>
                          reorder
                        </v-icon>
                        {{ idx + 1 }}
                      </div>
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <item-autocomplete-filter
                        v-model="item.item"
                        label="Produto *"
                        prepend-inner-icon="inventory"
                        return-object
                        :item-types="['VENDA']"
                        show-other-measurements
                        :disabled="!!item.shipped"
                        :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
                      />
                    </v-col>
                    <v-col class="pt-1 pb-0 d-flex align-center">
                      <template v-if="!item.showWeight">
                        <masked-text-field
                          v-model="item.quantity"
                          label="Quantidade *"
                          prepend-inner-icon="subtitles"
                          unmask
                          :mask="masks.float"
                          inputmode="numeric"
                          :suffix="item.item?.measurement"
                          :rules="[
                            v => !!v && item.quantity > 0 || 'Campo obrigatório!',
                            () => !item.shipped || item.quantity >= item.shipped || 'Quantidade menor que a expedida!',
                          ]"
                          validate-on-blur
                          @input="onQuantityChange($event, item)"
                        />
                      </template>
                      <template v-else>
                        <masked-text-field
                          v-if="item.item.grossWeight"
                          v-model="item.grossWeight"
                          label="Peso *"
                          prepend-inner-icon="scale"
                          unmask
                          :mask="masks.float"
                          inputmode="numeric"
                          suffix="KG"
                          :rules="[
                            v => !!v && item.quantity > 0 || 'Campo obrigatório!',
                            () => !item.shipped || item.quantity >= item.shipped || 'Quantidade menor que a expedida!',
                          ]"
                          validate-on-blur
                          @input="onWeightChange($event, item)"
                        />
                      </template>
                      <template v-if="item.item.grossWeight">
                        <span class="text-subtitle-1 ml-4 mr-2">
                          <template v-if="!item.showWeight">
                            ({{ formatNumber(item.grossWeight) }} KG)
                          </template>
                          <template v-else>
                            ({{ formatNumber(item.quantity) }} {{ item.item?.measurement }})
                          </template>
                        </span>
                        <v-btn
                          icon
                          @click="item.showWeight = !item.showWeight"
                        >
                          <v-icon>
                            sync_alt
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-col>
                    <v-col
                      class="px-0 d-flex flex-column justify-center"
                      style="max-width: 49px;"
                    >
                      <v-btn
                        icon
                        :disabled="item.shipped > 0"
                        @click="removeProduct(idx)"
                      >
                        <v-icon>
                          delete
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <div class="d-flex justify-end">
                  <v-btn
                    outlined
                    color="blue"
                    class="my-2"
                    @click="addProduct"
                  >
                    <v-icon>add</v-icon> Adicionar
                  </v-btn>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-form>
          <small>* Campo obrigatório</small>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="secondary"
            outlined
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { computed, ref, reactive } from 'vue'

import PersonAutocompleteFilter from '@/Support/Components/Filters/PersonAutocompleteFilter.vue'
import ItemAutocompleteFilter from '@/Support/Components/Filters/ItemAutocompleteFilter.vue'
import DateTimeTextField from '@/Support/Components/DateTimeTextField.vue'
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import { useDialogHistory } from '@/Support/Composables/dialogHistory.js'

import { v4 as uuidv4 } from 'uuid'

// eslint-disable-next-line no-undef
defineProps({
  hideDeliveryDate: Boolean
})

// eslint-disable-next-line no-undef
const emit = defineEmits(['save'])

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
  integer: { mask: Number, min: 0, scale: 0, signed: false },
}

const form = reactive({
  id: null,
  customer: {},
  code: null,
  invoiceNumber: null,
  dateTime: null,
  notes: null,
  products: [],
})

const title = computed(() => form.id ? 'Editando Entrega' : 'Nova Entrega')

const dialog = ref(false)

useDialogHistory(dialog)

const formRef = ref()

const show = (shipping) => {
  form.id = shipping.id
  form.customer = shipping.customer || {}
  form.code = shipping.code
  form.invoiceNumber = shipping.invoiceNumber
  form.dateTime = shipping.dateTime
  form.notes = shipping.notes
  if (shipping.products) {
    form.products = shipping.products.map(product => ({
      ...product,
      grossWeight: product.item.grossWeight ? product.quantity * product.item.grossWeight : null,
      showWeight: !!product.item.grossWeight,
    }))
  } else {
    form.products = [{ id: uuidv4(), item: {}, showWeight: false }]
  }

  dialog.value = true

  formRef.value?.reset()
}

const close = () => {
  form.id = null
  dialog.value = false
}

const save = async () => {
  if (!await formRef.value?.validate()) {
    return
  }

  return emit('save', { ...form })
}

const removeProduct = (idx) => {
  form.products.splice(idx, 1);
}

const addProduct = () => {
  form.products.push({ id: uuidv4(), item: {}, showWeight: false })
}

const onQuantityChange = (quantity, item) => {
  if (!item.item.grossWeight) {
    return
  }

  item.grossWeight = quantity * item.item.grossWeight
}

const onWeightChange = (grossWeight, item) => {
  item.quantity = grossWeight / item.item.grossWeight
}

const formatNumber = val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val)

// eslint-disable-next-line
defineExpose({
  show,
  close
})
</script>
