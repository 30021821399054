<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="700px"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card>
      <v-card-title class="text-h5">
        Transferências
      </v-card-title>
      <v-card-text>
        <template v-if="trailerTransfers.length > 0">
          <span class="flex-grow-1 text-h5 mt-4 black--text">Vinculados</span>
          <v-divider class="mb-4" />
          <v-data-table
            :headers="headers"
            :items="trailerTransfers"
            group-by="groupId"
            disable-pagination
            disable-sort
            disable-filtering
            hide-default-footer
            class="mb-2"
          >
            <template #[`group.header`]="{ group, items }">
              <td
                class="text-start"
                :colspan="headers.length - 1"
              >
                <b>Transferências Grupo:</b> {{ items.length }}
              </td>
              <td>
                <v-btn
                  small
                  color="error"
                  @click="onUnattachGroup(group)"
                >
                  Desvincular
                </v-btn>
              </td>
            </template>
            <template #[`item.volume`]="{ value }">
              {{ formatNumber(value) }} L
            </template>
            <template #[`item.date`]="{ value }">
              {{ formatDate(value, 'DD/MM HH:mm') }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                small
                color="error"
                @click="onUnattach(item)"
              >
                Desvincular
              </v-btn>
            </template>
          </v-data-table>
        </template>
        <span class="flex-grow-1 text-h5 mt-4 black--text">Disponíveis</span>
        <v-divider class="mb-4" />
        <v-data-table
          :headers="headers"
          :items="transfers"
          group-by="groupId"
          disable-pagination
          disable-sort
          disable-filtering
          hide-default-footer
        >
          <template #[`group.header`]="{ group, items }">
            <td
              class="text-start"
              :colspan="headers.length - 1"
            >
              <b>Transferências Grupo:</b> {{ items.length }}
            </td>
            <td>
              <v-btn
                small
                color="success"
                @click="onAttachGroup(group)"
              >
                Vincular
              </v-btn>
            </td>
          </template>
          <template #[`item.volume`]="{ value }">
            {{ formatNumber(value) }} L
          </template>
          <template #[`item.date`]="{ value }">
            {{ formatDate(value, 'DD/MM HH:mm') }}
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn
              small
              color="success"
              @click="onAttach(item)"
            >
              Vincular
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey darken-1"
          outlined
          @click="close"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="attachDialog.show"
      persistent
      max-width="500px"
    >
      <v-card class="card-dialog">
        <v-card-title class="text-h5">
          Vincular Transferência
          <v-chip
            small
            dark
            class="ml-3"
            :color="attachDialog.model === 'REBOQUE' ? 'orange' : 'purple'"
          >
            {{ attachDialog.model }}
          </v-chip>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :value="attachDialog.transfers.length"
                  label="Transferências"
                  disabled
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="attachDialog.volume"
                  label="Volume"
                  disabled
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-select
                  v-model="attachDialog.model"
                  :items="['REBOQUE', 'TRANSBORDO']"
                  label="Tipo *"
                  :rules="[v => !!v || 'Campo obrigatório!']"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-autocomplete
                  v-if="attachDialog.model === 'REBOQUE'"
                  v-model="attachDialog.equipment"
                  label="Reboque *"
                  :items="trailers"
                  item-text="plate"
                  item-value="plate"
                  return-object
                  :rules="[v => !!v?.plate || 'Campo obrigatório!']"
                />
                <v-text-field
                  v-else
                  :value="vehicle?.plate"
                  label="Veículo"
                  disabled
                />
              </v-col>
              <v-col>
                <v-alert
                  text
                  dense
                  color="info"
                >
                  <div
                    v-if="attachDialog.model === 'REBOQUE'"
                    class="text-body-1"
                  >
                    As transferências serão lançadas nos tanques da Julieta/Reboque
                  </div>
                  <div
                    v-else
                    class="text-body-1"
                  >
                    As transferências serão lançadas nos tanques do Veículo Principal
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            outlined
            @click="attachDialog.show = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            outlined
            @click="saveTransfer"
          >
            Transferir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import moment from "moment-timezone";
import isArray from "lodash/fp/isArray"
import isObject from "lodash/fp/isObject"

export default {

  data() {
    return {
      dialog: false,

      itineraryId: null,

      vehicle: null,

      trailers: [],

      trailerTransfers: [],

      transfers: [],

      headers: [
        { text: 'Origem', value: 'equipmentOrigin.plate' },
        { text: 'Destino', value: 'equipment.plate' },
        { text: 'Volume', value: 'volume' },
        { text: 'Tipo', value: 'model' },
        { text: 'Data', value: 'date', width: 100 },
        { text: '', value: 'actions',  width: 100 },
      ],

      attachDialog: {
        show: false,
        transfers: [],
        volume: 0,
        model: 'REBOQUE',
        equipment: {},
      },
    }
  },

  methods: {

    show({ itineraryId, vehicle, trailers, trailerTransfers }) {
      this.itineraryId = itineraryId;
      this.trailerTransfers = trailerTransfers;
      this.vehicle = vehicle;
      this.trailers = trailers;

      this.dialog = true;

      this.loadOpenTransfers();
    },

    async loadOpenTransfers() {
      try {
        this.$root.$progressBar.loading()

        const { data } = await this.$axios.get(`/itinerario/transferenciasSemAcoplamento`);

        if (!isArray(data)) {
          throw new Error(data);
        }

        this.transfers = data.map(transfer => ({
          id: transfer.id,
          model: transfer.tipo,
          groupId: transfer.id_agrupamento,
          itineraryId: transfer.id_itinerario,
          date: transfer.data_hora_atualizacao,
          equipmentOrigin: {
            id: transfer.id_equipamento_origem,
            plate: transfer.placa_origem,
          },
          equipment: {
            id: transfer.id_equipamento_destino,
            plate: transfer.placa_destino,
          },
          volume: transfer.volume,
          transfers: transfer.transferencias
        }));
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as transferências em aberto!",
          "Atenção"
        );
      } finally {
        this.$root.$progressBar.hide()
      }
    },

    async onAttachGroup(groupId) {
      const transfers = this.transfers.filter(t => t.groupId == groupId);
      const volume = transfers.reduce((acc, t) => acc + t.volume, 0);

      this.attachDialog.show = true
      this.attachDialog.transfers = transfers
      this.attachDialog.volume = volume
      this.attachDialog.model = transfers[0].model
      this.attachDialog.equipment = transfers[0].equipment
    },

    async onAttach(transfer) {
      this.attachDialog.show = true
      this.attachDialog.transfers = [transfer]
      this.attachDialog.volume = transfer.volume
      this.attachDialog.model = transfer.model
      this.attachDialog.equipment = transfer.equipment
    },

    async saveTransfer() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const model = this.attachDialog.model

      const transfers = this.attachDialog.transfers.map(transfer => ({
        ...transfer,
        equipment: model === 'REBOQUE' ? this.attachDialog.equipment : this.vehicle,
      }))

      this.changeAttach(transfers, 'ACOPLAR', model)
    },

    async onUnattachGroup(groupId) {
      const transfers = this.trailerTransfers.filter(t => t.groupId == groupId);
      const volume = transfers.reduce((acc, t) => acc + t.volume, 0);
      const model = this.trailerTransfers[0].model;

      if (!(await this.$root.$confirm(
        `Deseja realmente desvincular?`,
        `<span class="body-1">Transferências: <b>${transfers.length}</b><br>Destino: <b>${transfers[0].equipment.plate}</b><br>Volume: <b>${volume} L</div>`,
        { color: 'error' }
      ))
      ) {
        return;
      }

      this.changeAttach(transfers, 'DESACOPLAR', model);
    },

    async onUnattach(transfer) {
      if (!(await this.$root.$confirm(
        `Deseja realmente desvincular?`,
        `<span class="body-1">Destino: <b>${transfer.equipment.plate}</b><br>Volume: <b>${transfer.volume} L</div>`,
        { color: 'error' }
      ))
      ) {
        return;
      }

      const model = transfer.model;

      this.changeAttach([transfer], 'DESACOPLAR', model);
    },

    async changeAttach(transfers, type, model) {
      try {
        this.$root.$progressBar.loading()

        const payload = {
          id_itinerario_destino: this.itineraryId,
          tipo: type,
          modelo: model,
          transferencias: transfers.map(t => ({
            id: t.id,
            id_itinerario: t.itineraryId,
            id_equipamento_destino: t.equipment.id,
            placa_destino: t.equipment.plate,
          })),
        };

        const { data } = await this.$axios.post(
          `/itinerario/acoplarReboque`,
          payload
        );

        if (!isObject(data) || !data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success('Transferência salva com sucesso', 'Sucesso');

        this.$emit('save');
        this.close();

      } catch (error) {
        this.$snotify.error(`Erro ao alterar acoplamento: ${error}`, "Atenção");
        console.error(error);
      } finally {
        this.$root.$progressBar.hide()
      }
    },

    close() {
      this.transfers = [];
      this.dialog = false;
    },

    formatDate: (value, format) => moment(value).format(format),
    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),
  },
}
</script>
