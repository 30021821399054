<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="800px"
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="order-dialog">
      <v-card-title>
        Entrada de Ordem de Produção nº {{ order.code }}
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="pt-5"
        style="max-height: calc(100vh - 250px);"
      >
        <v-form
          ref="formRef"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-text-field
                :value="form.item.description"
                label="Produto"
                disabled
              />
            </v-col>
            <v-col
              cols="6"
              md="3"
              class="py-0"
            >
              <v-select
                v-if="isRawMaterial"
                v-model="form.silo"
                :items="availableSilos"
                label="Silo"
                item-value="id"
                item-text="label"
                placeholder=" "
                return-object
                prepend-inner-icon="panorama_vertical_select"
                :rules="[v => !!v || 'Campo obrigatório!']"
                @change="onSiloSelect"
              >
                <template #item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.label }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span v-if="item.rawProduct.id">
                        {{ item.rawProduct.name }}
                      </span>
                      <v-chip small>
                        {{ item.vol }}L / {{ item.capacity }}L
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
              <warehouse-select
                v-else-if="dialog"
                v-model="form.warehouseId"
                label="Depósito"
                auto-select-default
                :rules="[v => !!v || 'Campo obrigatório!']"
                @change="onLotChanged()"
              />
            </v-col>
            <v-col
              cols="6"
              md="3"
              class="py-0 d-flex"
            >
              <v-text-field
                v-model="form.lotNumber"
                label="Nº Lote"
                prepend-inner-icon="subtitles"
                :disabled="isRawMaterial"
                class="flex-grow-1"
                :rules="form.item?.requiresLot ? [v => !!v || 'Campo obrigatório!'] : []"
                @change="onLotChanged()"
              >
                <template #append>
                  <v-tooltip
                    v-if="!form.lotNumber && form.item?.lotFormat"
                    top
                  >
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        small
                        v-on="on"
                        @click="generateLotNumber()"
                      >
                        <v-icon small>
                          autorenew
                        </v-icon>
                      </v-btn>
                    </template>
                    Gerar Lote
                  </v-tooltip>
                </template>
              </v-text-field>
              <masked-text-field
                v-if="form.lotNumber && !isRawMaterial"
                v-model="form.lotNumberSuffix"
                prefix="-"
                label="Variação Lote"
                placeholder="Tanque, Queijomatic, etc."
                maxlength="2"
                :mask="masks.integer"
                unmask
                class="flex-grow-0 ml-2"
                style="max-width: 90px;"
                @input="onLotChanged()"
              />
            </v-col>
            <template v-if="!isRawMaterial">
              <v-col
                cols="6"
                md="3"
                class="py-0"
              >
                <date-text-field
                  v-model="form.manufacturingDate"
                  label="Fabricação"
                  manual
                  :disabled="!form.lotNumber"
                  @input="onManufacturingDateChange()"
                />
              </v-col>
              <v-col
                cols="6"
                md="3"
                class="py-0"
              >
                <date-text-field
                  v-model="form.expirationDate"
                  label="Validade"
                  manual
                  max=""
                  :disabled="!form.lotNumber"
                  :rules="!!form.lotNumber ? [v => !!v || 'Campo obrigatório!'] : []"
                />
              </v-col>
            </template>
            <v-col
              v-if="!form.item?.scaleItem"
              cols="6"
              md="3"
              class="py-0"
            >
              <masked-text-field
                v-model="form.quantity"
                label="Quantidade"
                :mask="masks.float"
                unmask
                inputmode="numeric"
                :suffix="form.item?.measurement"
                :rules="[
                  v => !!v || 'Campo obrigatório!',
                  validateQuantity()
                ]"
                validate-on-blur
              />
            </v-col>
            <v-col
              v-if="!isRawMaterial"
              class="py-0"
            >
              <v-select
                v-model="form.lotStatus"
                :items="[
                  { text: 'LIBERADO', value: 'LIBERADO' },
                  { text: 'RESTRITO', value: 'RESTRITO' },
                  { text: 'SUSPENSO', value: 'SUSPENSO' },
                ]"
                label="Status Lote"
              />
            </v-col>
            <v-col
              v-if="showQueijomatic"
              class="py-0"
            >
              <v-select
                v-model="form.tank"
                :items="tinas"
                label="Queijomatic *"
                item-value="id"
                item-text="name"
                return-object
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
          </v-row>

          <scale-weighing
            v-if="form.item?.scaleItem && dialog"
            v-model="form"
            settings-id="PRODUCT-WEIGHING"
            hide-tare
            hide-save
            @save="save"
          >
            <template #append>
              <v-menu
                v-if="hasSettingsPermission"
                v-model="settings.dialog"
                :close-on-content-click="false"
                top
                left
                content-class="settings-menu"
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    small
                    @click="openSettings"
                  >
                    <v-icon>settings</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>Configurações</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="settings.computerId"
                          label="ID Computador"
                          outlined
                          small
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="settings.tagModel"
                          label="Modelo Etiqueta"
                          :items="[
                            { text: 'Produção (10x15)', value: 'PRODUCTION_10X15' },
                          ]"
                          outlined
                          small
                          clearable
                          hide-details
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="settings.autoWeighing"
                          label="Peso automático"
                          :items="[
                            { text: 'Sim', value: true },
                            { text: 'Não', value: false }
                          ]"
                          outlined
                          small
                          hide-details
                          :disabled="!hasManualWeighingPermission"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="settings.packingScale"
                          label="Balança Embalagem"
                          :items="[
                            { text: 'Balança 1', value: 'weighingScale' },
                            { text: 'Balança 2', value: 'weighingScale2' }
                          ]"
                          outlined
                          small
                          hide-details
                        />
                      </v-col>
                      <v-col
                        v-if="settings.autoWeighing"
                        cols="6"
                      >
                        <masked-text-field
                          v-model="settings.minBoxWeight"
                          label="Peso Bruto Mínimo Caixa"
                          suffix="Kg"
                          :mask="masks.float"
                          unmask
                          inputmode="numeric"
                          outlined
                          small
                          hide-details
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="settings.autoPrinting"
                          label="Etiqueta automática"
                          :items="[
                            { text: 'Sim', value: true },
                            { text: 'Não', value: false }
                          ]"
                          outlined
                          small
                          hide-details
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        class="pb-0"
                      >
                        <masked-text-field
                          v-model="settings.scaleDelay"
                          label="Delay Balança"
                          suffix="seg"
                          :mask="{ mask: Number, min: 0.5, max: 3, scale: 1 }"
                          unmask="typed"
                          inputmode="numeric"
                          outlined
                          small
                          hint="Atraso de 0,5 a 3,0 segundos para leitura do peso"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      @click="saveSettings"
                    >
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </template>
          </scale-weighing>
        </v-form>

        <template v-if="records.length > 0 || transfers.length > 0">
          <div class="d-flex">
            <span class="flex-grow-1 text-h5 mt-4 black--text">Registros de Estoques</span>
          </div>

          <v-divider class="mb-4" />
        </template>

        <v-data-table
          v-if="records.length > 0"
          :headers="recordsHeaders"
          :items="records"
          disable-pagination
          disable-sort
          disable-filtering
          hide-default-footer
        >
          <template #[`item.date`]="{ value }">
            {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
          </template>
          <template #[`item.quantity`]="{ item }">
            <span
              :class="{
                'green--text': item.operation === 'ENTRADA',
                'red--text': item.operation === 'SAIDA',
              }"
            >
              {{ formatNumber(item.quantity) }} {{ item.measurement }}
            </span>
          </template>
          <template #[`item.manufacturingDate`]="{ value }">
            {{ formatDate(value, 'DD/MM/YYYY') }}
          </template>
          <template #[`item.expirationDate`]="{ value }">
            {{ formatDate(value, 'DD/MM/YYYY') }}
          </template>

          <template #[`item.actions`]="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="form.item?.scaleItem && form.item?.id === item.product?.id"
                  @click="printStock(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      print
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Imprimir
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="hasChargebackAccess"
                  @click="chargebackStock(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      settings_backup_restore
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Estornar
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <v-data-table
          v-else-if="transfers.length > 0"
          :headers="transfersHeaders"
          :items="transfers"
          disable-pagination
          disable-sort
          disable-filtering
          hide-default-footer
        >
          <template #[`item.transferredVol`]="{ value }">
            {{ formatNumber(value) }} L
          </template>
          <template #[`item.transferredAt`]="{ value }">
            {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
          </template>
          <template #[`item.rawMaterial.description`]="{ value }">
            <v-chip
              v-if="value"
              x-small
            >
              {{ value }}
            </v-chip>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="hasChargebackAccess"
                  @click="chargebackMovement(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      settings_backup_restore
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Estornar
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, reactive, computed, nextTick } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import { usePermissions } from '@/Support/Composables/permissions.js'
import axios from '@/Support/Resources/axios-instance.js'
import store from '@/Support/Resources/vuex.js'

import WarehouseSelect from '@/Domains/Registrations/Item/Components/WarehouseSelect.vue'
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import DateTextField from '@/Support/Components/DateTextField.vue'
import ScaleWeighing from '@/Domains/Shipment/StorageControl/Components/ScaleWeighing.vue'

import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js'
import { useRangeValidator } from '@/Support/Composables/validator.js'

import { usePacking } from '@/Domains/Shipment/Composable/Packing.js'

import moment from 'moment'

import ZebraPrinterGenerator from '@/Domains/Shipment/Api/ZebraPrinterGenerator.js'

import { useLotNumber } from '@/Domains/Registrations/Item/Composables/lotNumber.js'

const { progressBar, notify, confirm } = useUtils()

const { getLotNumber, generatedLotNumber, resetLotNumber } = useLotNumber()

const { hasPermission } = usePermissions()

const { validateRule } = useRangeValidator()

const hasChargebackAccess = computed(() => hasPermission('ordem-prod-exclusao-apontamento'))
const hasSettingsPermission = computed(() => hasPermission('packing-settings'))

const { packingSettings, savePackingSettings, printTag } = usePacking('PRODUCT-WEIGHING')

// eslint-disable-next-line
const props = defineProps({
  order: {
    type: Object,
    default: () => ({}),
  },
  silos: Array,
  tinas: Array,
})

// eslint-disable-next-line
const emit = defineEmits(['save'])

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
  integer: { mask: Number, min: 0, scale: 0, signed: false },
}

const isRawMaterial = computed(() => !!form.value.item?.isRawMaterial)

const availableSilos = computed(() => {
  if (!isRawMaterial.value) {
    return []
  }

  return props.silos.filter(silo => !silo.rawProduct.id || form.value.item.id === silo.rawProduct.id)
})

const showQueijomatic = computed(() => form.value.showQueijomatic && !isRawMaterial.value)

const dialog = ref(false)
const formRef = ref()
const form = ref({
  item: {},
  lotNumber: null,
  lotFormat: null,
  manufacturingDate: null,
  showQueijomatic: false,
  warehouseId: null,
  silo: null,
  tank: null,
  accomplishedQuantity: 0,
  quantity: null,
  grossWeight: null,
  expirationDate: null,
})

const onSiloSelect = (silo) => {
  form.value = {
    ...form.value,
    lotNumber: silo.lotNumber || null,
    lotFormat: null,
  }
}

const show = async (data) => {
  dialog.value = true

  form.value = {
    item: { ...data.item },
    lotNumber: data.lotNumber,
    lotFormat: data.lotFormat,
    tolerance: data.tolerance,
    range: data.range,
    manufacturingDate: moment().format('YYYY-MM-DD'),
    showQueijomatic: data.showQueijomatic || false,
    warehouseId: null,
    silo: null,
    tank: null,
    accomplishedQuantity: data.accomplishedQuantity || 0,
    quantity: data.quantity || null,
    grossWeight: null,
    expirationDate: null,
  }

  transfers.value = data.transfers || []
  records.value = data.records || []

  await nextTick()

  // Se não houver lote informado, seleciona o primeiro disponivel
  if (!form.value.lotNumber) {
    const lot = props.order.lots.find((lot) => lot.warehouse.id === form.value.warehouseId && lot.product.id === form.value.item.id)

    form.value.lotNumber = lot?.lotNumber
  }

  if (form.value.lotNumber) {
    onLotChanged()
  } else {
    onManufacturingDateChange()
  }

  formRef.value?.resetValidation()
}

const onLotChanged = () => {
  const lotNumber = form.value.lotNumberSuffix ? `${form.value.lotNumber}-${form.value.lotNumberSuffix}` : form.value.lotNumber

  if (lotNumber) {
    const lot = props.order.lots.find((lot) => lot.lotNumber === lotNumber && lot.warehouse.id === form.value.warehouseId && lot.product.id === form.value.item.id)

    if (lot) {
      form.value.manufacturingDate = lot.manufacturingDate
      form.value.expirationDate = lot.expirationDate
      form.value.lotStatus = lot.lotStatus
      return
    }
  }

  setExpirationDate()
}

const onManufacturingDateChange = async () => {
  const lotNumber = await getLotNumber(form.value.item.id, form.value.item.lotFormat, form.value.manufacturingDate)

  if (lotNumber) {
    form.value.lotNumber = lotNumber
  }

  setExpirationDate()
}

const setExpirationDate = () => {
  const { expirationDays } = form.value.item

  if (!expirationDays) {
    return
  }

  if (!form.value.manufacturingDate) {
    form.value.expirationDate = null
    return
  }

  form.value.expirationDate = moment(form.value.manufacturingDate).add(expirationDays, 'days').format('YYYY-MM-DD')
}

const isSaved = ref(false)

const close = () => {
  dialog.value = false

  resetLotNumber()

  if (isSaved.value) {
    emit('save')
  }

  isSaved.value = false
}

const save = async () => {
  if (!await formRef.value.validate()) {
    return
  }

  try {
    progressBar.saving()

    if (packingSettings.value.autoPrinting && form.value.grossWeight) {
      print(form.value)
    }

    const quantity = form.value.quantity || form.value.grossWeight

    const payload = {
      id_item: form.value.item.id,
      id_deposito: form.value.warehouseId,
      id_silo: form.value.silo?.id,
      id_tina: form.value.tank?.id,
      quantidade: quantity,
      numero_lote: form.value.lotNumber,
      numero_lote_sufixo: form.value.lotNumberSuffix,
      status_lote: form.value.lotStatus,
      data_fabricacao: form.value.manufacturingDate,
      data_validade: form.value.expirationDate,
      atualizar_sequencia: form.value.item.lotFormat?.includes('SEQ') && generatedLotNumber.value === form.value.lotNumber,
    }

    if (isRawMaterial.value) {
      payload.id_materia_prima = form.value.item.id
      payload.nome_materia_prima = form.value.item.name || form.value.item.description
    }

    const response = await api.production(props.order.id, payload)

    if (isRawMaterial.value) {
      transfers.value.push({
        movementId: response.id,
        silo: { ...form.value.silo },
        transferredAt: moment().format('YYYY-MM-DD HH:mm'),
        transferredVol: quantity,
        batchNumber: form.value.lotNumber,
        rawMaterial: { ...form.value.item },
      })
    } else {
      const lotNumber = form.value.lotNumberSuffix ? `${form.value.lotNumber}-${form.value.lotNumberSuffix}` : form.value.lotNumber

      records.value.push({
        id: response.id,
        product: { ...form.value.item },
        date: moment().format('YYYY-MM-DD HH:mm'),
        quantity: quantity,
        lotNumber,
        lotStatus: form.value.lotStatus,
        manufacturingDate: form.value.manufacturingDate,
        expirationDate: form.value.expirationDate,
        tank: { ...form.value.tank },
        operation: 'ENTRADA',
        measurement: form.value.item.measurement,
      })
    }

    form.value.quantity = null
    form.value.grossWeight = null

    isSaved.value = true

    if (!form.value.item?.scaleItem) {
      close()
    }

    notify.success('Produção efetuada com sucesso', 'Sucesso')
  } catch (e) {
    const message = e?.response?.data?.message || 'Erro ao salvar'
    notify.error(message, 'Atenção')
    console.warn(e)
  } finally {
    progressBar.hide()
  }
}

const generateLotNumber = async () => {
  form.value.manufacturingDate = moment().format('YYYY-MM-DD')

  onManufacturingDateChange()
}

const validateQuantity = () => {
  const value = form.value.quantity

  // Usado na entrada de subprodutos
  if (form.value.range) {
    const rule = form.value.range

    return validateRule(value, rule)
  }

  // Usado na entrada de produção
  if (form.value.tolerance?.max) {
    const rule = {
      max: form.value.tolerance.max - form.value.accomplishedQuantity,
    }

    return validateRule(value, rule)
  }

  return true
}

const print = (product) => {
  const tagModel = packingSettings.value.tagModel

  if (tagModel === 'PRODUCTION_10X15') {
    const dairy = store.state.settings?.laticinio || {}

    const rawData = ZebraPrinterGenerator.productionTag10x15({
      dairyName: dairy.name,
      dairyDoc: dairy.doc,
      dairyCity: dairy.address?.city,
      dairyState: dairy.address?.state,
      dairyStreet: dairy.address?.street,
      dairyNumber: dairy.address?.number,

      grossWeight: product.grossWeight,
      tareWeight: product.item.tareWeight || 0,
      netWeight: product.grossWeight - (product.item.tareWeight || 0),

      productName: product.item.name,
      quantity: 1,
      expirationDate: product.expirationDate,
      manufacturingDate: product.manufacturingDate,
      lotNumber: product.lotNumber,

      gtin: product.item.barcodes?.[dairy.id] || product.item.barcode,
      sif: product.item.sifDipoa?.[dairy.id] || product.item.sif,
    })

    return printTag(rawData)
  }
}

const printStock = (item) => print({
  item: form.value.item,
  grossWeight: item.quantity,
  manufacturingDate: item.manufacturingDate,
  expirationDate: item.expirationDate,
  lotNumber: item.lotNumber,
})

const hasManualWeighingPermission = computed(() => hasPermission('manual-weighing'))

const settings = reactive({
  dialog: false,
  computerId: null,
  tagModel: null,
  autoWeighing: false,
  autoPrinting: false,
  minBoxWeight: null,
  packingScale: null,
  scaleDelay: null,
})

const openSettings = () => {
  settings.dialog = true
  settings.computerId = packingSettings.value.computerId
  settings.tagModel = packingSettings.value.tagModel
  settings.autoWeighing = packingSettings.value.autoWeighing || !hasManualWeighingPermission.value
  settings.autoPrinting = packingSettings.value.autoPrinting
  settings.minBoxWeight = packingSettings.value.minBoxWeight
  settings.packingScale = packingSettings.value.packingScale
  settings.scaleDelay = packingSettings.value.scaleDelay
}

const saveSettings = async () => {
  try {
    progressBar?.saving()

    await savePackingSettings({
      computerId: settings.computerId,
      tagModel: settings.tagModel,
      autoWeighing: settings.autoWeighing,
      autoPrinting: settings.autoPrinting,
      minBoxWeight: settings.minBoxWeight,
      packingScale: settings.packingScale,
      scaleDelay: settings.scaleDelay,
    })

  } catch (error) {
    console.error(error)
    const message = error?.response?.data?.message || 'Erro ao salvar as configurações'
    notify.error(message, 'Atenção', { timeout: 6000 })
  } finally {
    progressBar?.hide()
    settings.dialog = false
  }
}

const formatDate = (value, format) => !value ? '-' : moment(value).format(format)
const formatNumber = (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value)

const recordsHeaders = computed(() => {
  const headers = [
    { text: 'Produto', value: 'product.name' },
    { text: 'Data e Hora', value: 'date' },
    { text: 'Qtde.', value: 'quantity', align: 'center' },
    { text: 'Nº Lote', value: 'lotNumber' },
    { text: 'Fabricação', value: 'manufacturingDate' },
    { text: 'Validade', value: 'expirationDate' },
  ]

  if (showQueijomatic.value) {
    headers.push({ text: 'Queijomatic', value: 'tank.name', align: 'center' })
  }

  headers.push({ text: '', value: 'actions', align: 'center', sortable: false })

  return headers
})

const transfersHeaders = [
  { text: 'Silo', value: 'silo.description' },
  { text: 'Data e Hora', value: 'transferredAt' },
  { text: 'Volume', value: 'transferredVol', align: 'center' },
  { text: 'Nº Lote', value: 'batchNumber' },
  { text: 'Matéria Prima', value: 'rawMaterial.description' },
  { text: '', value: 'actions', align: 'center', sortable: false },
]

const records = ref([])

const transfers = ref([])

const chargebackStock = async ({ id }) => {
  if (!(await confirm('Atenção', `Deseja realmente estornar esta entrada?<br><br>`, { color: 'red', token: 'ESTORNAR' }))) {
    return
  }

  try {
    progressBar.loading()

    await axios.post(`/stock/chargeback`, {
      id: id,
    })

    const idx = records.value.findIndex(record => record.id === id)

    records.value.splice(idx, 1)

    notify.success('Estorno efetuado com sucesso', 'Sucesso')
  } catch (e) {
    console.warn(e)

    notify.error('Oops, ocorreu um erro ao estornar!', 'Atenção')
  } finally {
    progressBar.hide()
  }
}

const chargebackMovement = async ({ movementId }) => {
  if (!(await confirm('Atenção', `Deseja realmente estornar esta movimentação?<br><br>`, { color: 'red', token: 'ESTORNAR' }))) {
    return
  }

  try {
    progressBar.loading()

    await axios.post(`/industry/silo/chargeback-movement`, {
      id: movementId,
    })

    const idx = transfers.value.findIndex(movement => movement.movementId === movementId)

    transfers.value.splice(idx, 1)

    notify.success('Estorno efetuado com sucesso', 'Sucesso')
  } catch (e) {
    console.warn(e)

    notify.error('Oops, ocorreu um erro ao estornar!', 'Atenção')
  } finally {
    progressBar.hide()
  }
}

// eslint-disable-next-line no-undef
defineExpose({ show })
</script>
