<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        Detalhes do {{ stock.lotNumber ? 'Lote' : 'Estoque' }}
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="detail-dialog pt-5"
      >
        <v-row v-if="stock.lotNumber">
          <v-col class="pb-0">
            <v-text-field
              :value="stock.lotNumber"
              label="Lote"
              disabled
              persistent-placeholder
            />
          </v-col>
          <v-col class="pb-0">
            <v-text-field
              :value="stock.manufacturingDate"
              label="Fabricação"
              type="date"
              disabled
              persistent-placeholder
            />
          </v-col>
          <v-col class="pb-0">
            <v-text-field
              :value="stock.expirationDate"
              label="Validade"
              type="date"
              disabled
              persistent-placeholder
            />
          </v-col>
          <v-col class="pb-0">
            <v-text-field
              :value="stock.supplierName"
              label="Fornecedor"
              disabled
              persistent-placeholder
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            class="pb-0"
          >
            <masked-text-field
              :value="stock.quantity"
              label="Estoque Físico"
              :mask="masks.float"
              unmask
              disabled
              persistent-placeholder
            />
          </v-col>
          <v-col
            class="pb-0"
          >
            <masked-text-field
              :value="stock.reservedQuantity"
              label="Estoque Reservado"
              :mask="masks.float"
              unmask
              disabled
              persistent-placeholder
            />
          </v-col>
          <v-col
            class="pb-0"
          >
            <masked-text-field
              :value="stock.availableQuantity"
              label="Estoque Disponível"
              :mask="masks.float"
              unmask
              disabled
              persistent-placeholder
            />
          </v-col>
          <v-col
            class="pb-0"
          >
            <money-input
              :value="stock.price"
              label="Preço unitário"
              disabled
              persistent-placeholder
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="pb-0"
          >
            <v-data-table
              v-if="dialog"
              :headers="headers"
              :items="records"
              :loading="loading"
              :options.sync="options"
              :server-items-length="total"
              light
              disable-sort
            >
              <template #[`item.operation`]="{ value }">
                <v-icon
                  v-if="value == 'ENTRADA'"
                  color="green accent-3"
                >
                  south_west
                </v-icon>
                <v-icon
                  v-else
                  color="red accent-3"
                >
                  north_east
                </v-icon>
              </template>
              <template #[`item.date`]="{ value }">
                {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
              </template>
              <template #[`item.quantity`]="{ value }">
                <span
                  :class="{
                    'red--text text--accent-4' : value < 0,
                    'green--text text--accent-4' : value > 0
                  }"
                >
                  {{ formatNumber(value) }}
                </span>
              </template>
              <template #[`item.lotStock`]="{ value }">
                {{ formatNumber(value) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.detail-dialog {
  .theme--light.v-label--is-disabled, .theme--light.v-input--is-disabled {
    color: rgba(0, 0, 0, 0.8) !important;
  }

  .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
</style>

<script setup>
import { ref, watch } from 'vue'

import moment from 'moment'
import axios from '@/Support/Resources/axios-instance.js'

import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import MoneyInput from '@/Support/Components/MoneyInput.vue'

import { useUtils } from '@/Support/Composables/utils.js'

const { notify } = useUtils()

const headers = [
  { text: '', value: 'operation' },
  { text: 'Tipo', value: 'type' },
  { text: 'Data e Hora', value: 'date', align: 'center' },
  { text: 'Quantidade', value: 'quantity', align: 'center' },
  { text: 'Estoque', value: 'lotStock', align: 'center' },
]

const masks = {
  float: { mask: Number, signed: true },
}

const dialog =  ref(false)
const stock =  ref({})
const itemId =  ref(null)
const warehouseId =  ref(null)
const records =  ref([])
const options =  ref({})
const total =  ref(0)
const loading =  ref(false)

watch(options, () => loadHistory(), { deep: true })

const close = () => {
  dialog.value = false
  stock.value = {}
  records.value = []
}

const show = (data) => {
  dialog.value = true
  itemId.value = data.itemId
  warehouseId.value = data.warehouseId
  stock.value = data.stock
}

const loadHistory = async () => {
  try {
    loading.value = true;
    const { page, itemsPerPage } = options.value

    const { data } = await axios.get(`/stock/history`, { params: {
      page,
      items_per_page: itemsPerPage,
      id_item: itemId.value,
      id_estoque: stock.value.id,
      id_deposito: warehouseId.value
    } });

    total.value = data.total;
    records.value = data.data.map(o => ({
      date: o.data_hora_cadastro,
      operation: o.entrada_saida == 1 ? 'ENTRADA' : 'SAIDA',
      type: getItemType(o),
      quantity: parseFloat(o.quantidade) * (o.entrada_saida == 2 ? -1 : 1),
      stock: parseFloat(o.estoque),
      price: parseFloat(o.valor),
      orderId: o.id_movimento,
      person: o.nome_pessoa_registro,
      lotNumber: o.numero_lote,
      lotStock: parseFloat(o.estoque_lote),
      notes: o.observacao,
    }))
  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao carregar histórico';
    notify.error(message, 'Atenção');
    console.warn(error);
  } finally {
    loading.value = false;
  }
}

const getItemType = (item) => {
  if (item.estorno) {
    return 'ESTORNO';
  }

  if (item.id_transferencia) {
    return 'TRANSFERÊNCIA';
  }

  if (item.id_movimento_item) {
    return item.entrada_saida == 2 ? 'VENDA' : 'ESTORNO VENDA';
  }

  if (item.id_nota_fiscal) {
    return item.entrada_saida == 2 ? 'SAÍDA NF' : 'ENTRADA NF';
  }

  if (item.id_ordem_producao || item.id_ordem_producao_item || item.id_ordem_producao_subproduto) {
    return item.entrada_saida == 2 ? 'SAÍDA OP' : 'ENTRADA OP';
  }

  if (item.id_entrega_item) {
    return item.entrada_saida == 2 ? 'SAÍDA EXPEDIÇÃO' : 'ENTRADA EXPEDIÇÃO';
  }

  return 'ACERTO';
}

const formatDate = (value, format) => !value ? '-' : moment(value).format(format)
const formatNumber = (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value)

// eslint-disable-next-line
defineExpose({
  show
})
</script>
