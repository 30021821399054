import moment from "moment-timezone";
import isEmpty from "lodash/isEmpty";

export default {

  data() {
    return {
      importAnalysis: {
        show: false,
      },

      importResult: {
        show: false,
        successes: 0,
        failures: 0
      }
    }
  },

  computed: {
    importFields() {
      /**
       * Obtém as colunas da última importação,
       * desse modo, seleciona automaticamente as colunas
       */
      const settings = this.$store.state.settings.gerais.campos_importados_analises || {};

      const fields = [
        { text: 'Data de Coleta', keys: ['Data de Coleta', 'Data', 'data', settings['Data de Coleta'] ].filter(o => o), required: true, example: moment().format('DD/MM/YYYY') },
        { text: 'Data da Análise', keys: ['Data da Análise', 'Data', 'data', settings['Data da Análise'] ].filter(o => o), required: false, example: moment().format('DD/MM/YYYY') },
        { text: 'Número Amostra', keys: ['Número Amostra', 'Amostra', 'numero_amostra', settings['Número Amostra'] ].filter(o => o), required: true, example: '299292/2022' },
        { text: 'Código Latícinio', keys: ['Código Latícinio', 'Codigo Laticinio', 'codigo_laticinio', settings['Código Latícinio'] ].filter(o => o), example: '000' },
        { text: 'CPF', keys: ['CPF', settings['CPF'] ].filter(o => o), example: '000.000.000-00' },
        { text: 'Nome', keys: ['Nome', 'Produtor', settings['Nome'] ].filter(o => o), example: 'ADÃO VALDINEI | ROTA 45 | SILO 1 | ANIMAL 72' },
        { text: 'Gordura (g/100g)', keys: ['Gordura (g/100g)', 'Gordura', settings['Gordura (g/100g)'] ].filter(o => o), example: 2.48 },
        { text: 'Proteína (g/100g)', keys: ['Proteína (g/100g)', 'Proteina', 'Proteina (g/100g)', settings['Proteína (g/100g)'] ].filter(o => o), example: 3.48 },
        { text: 'Lactose (g/100g)', keys: ['Lactose (g/100g)', 'Lactose', settings['Lactose (g/100g)'] ].filter(o => o), example: 5 },
        { text: 'Sólidos Totais (g/100g)', keys: ['Sólidos Totais (g/100g)', 'S.T', settings['Sólidos Totais (g/100g)'] ].filter(o => o), example: 10.20 },
        { text: 'ESD (g/100g)', keys: ['ESD (g/100g)', 'ESD', settings['ESD (g/100g)'] ].filter(o => o), example: 8.6 },
        { text: 'CCS (x1000 cél/mL)', keys: ['CCS (x1000 cél/mL)', 'CCS', 'CCS ( x1000 cél/mL)', settings['CCS (x1000 cél/mL)'] ].filter(o => o), example: 350 },
        { text: 'CPP (x1000 UFC/mL)', keys: ['CPP (x1000 UFC/mL)', 'CPP', 'CBT', settings['CPP (x1000 UFC/mL)'] ].filter(o => o), example: 500 },
        { text: 'Uréia (mg/dL)', keys: ['Uréia (mg/dL)', 'Uréia', 'Nitrogênio Ureico (mg/dL)', settings['Uréia (mg/dL)'] ].filter(o => o), example: 13.21 },
        { text: 'Caseína (g/100g)', keys: ['Caseína (g/100g)', 'Caseína', settings['Caseína (g/100g)'] ].filter(o => o), example: 2.6 },
      ];

      if (!this.importType) {
        fields.unshift({ text: 'Tipo', keys: ['Tipo', settings['Tipo'] ].filter(o => o), required: true, example: 'PRODUTOR | ROTA | SILO | CONTROLE_LEITEIRO' })
      }

      return fields;
    },
  },

  methods: {
    /**
     * Salva os nomes das colunas importadas nas configurações,
     * desse modo nas próximas importações as colunas serão selecionadas automaticamente
     */
    async saveLastImportedFields(fields) {
      try {
        let { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracaoAppQualidade`,
          this.$qs.stringify({
            configuracaoQualidade: JSON.stringify([{
              campos_importados_analises: fields.reduce((acc, cur) => ({ ...acc, [cur.text]: cur.value }), {})
            }]),
          })
        );

        [data] = data;

        if (data.codigo !== 1) {
          throw data;
        }

        this.$store.dispatch('updateStoreSetting');
      } catch (error) {
        this.$snotify.error("Erro ao salvar as configurações", "Atenção");
        console.error(error);
      }
    },

    async onImportAnalysis({ data, fields }) {
      try {
        this.$root.$progressBar.loading();

        this.importAnalysis.show = false;

        this.saveLastImportedFields(fields);

        const analysis = data.map(item => {
          const collectFormat = (item['Data de Coleta'] || '').includes('/') ? 'DD/MM/YYYY' : 'YYYY-MM-DD';
          const analysisFormat = (item['Data de Análise'] || '').includes('/') ? 'DD/MM/YYYY' : 'YYYY-MM-DD';

          const collectedAt = !isEmpty(item['Data de Coleta']) ? moment.tz(item['Data de Coleta'], collectFormat, 'America/Sao_Paulo').format('YYYY-MM-DD') : null;
          const analyzedAt = !isEmpty(item['Data de Análise']) ? moment.tz(item['Data de Análise'], analysisFormat, 'America/Sao_Paulo').format('YYYY-MM-DD') : null;

          return {
            data_coleta: collectedAt,
            data_analise: analyzedAt,
            numero_amostra: item['Número Amostra'],
            tipo: this.importType || item['Tipo'],
            codigo_laticinio: item['Código Latícinio'],
            cpf: String(item['CPF']).replace(/\D/g, '') || null,
            nome: item['Nome'],
            gordura: this.parseNumeric(item['Gordura (g/100g)']),
            proteina: this.parseNumeric(item['Proteína (g/100g)']),
            lactose: this.parseNumeric(item['Lactose (g/100g)']),
            solidos_totais: this.parseNumeric(item['Sólidos Totais (g/100g)']),
            esd: this.parseNumeric(item['ESD (g/100g)']),
            ccs: this.parseNumeric(item['CCS (x1000 cél/mL)']),
            cpp: this.parseNumeric(item['CPP (x1000 UFC/mL)']),
            ureia: this.parseNumeric(item['Uréia (mg/dL)']),
            caseina: this.parseNumeric(item['Caseína (g/100g)']),
          }
        });

        const { data: response } = await this.$axios.post(`/analysis/import`, {
          analysis
        });

        if (!response.codigo) {
          throw new Error(response.mensagem || response);
        }

        if (response.falhas === 0) {
          this.$snotify.success("Análises importadas com successo!");
        }

        this.importResult.successes = response.sucessos;
        this.importResult.failures = response.falhas;
        this.importResult.show = true

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao importar!", "Atenção");

        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
        this.loadReport();
      }
    },

    showImportAnalysis() {
      this.importAnalysis.show = true;
    },

    /**
     * Faz o parse do número caso esteja formatado
     * EX: 1.000,00 -> 1000.00
     *
     * @param {*} number
     */
    parseNumeric(number) {
      if (String(number).includes(',')) {
        number = number.replace('.', '').replace(',', '.');
      }
      return parseFloat(number) || null;
    },
  }
}
