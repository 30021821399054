<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    scrollable
    @click:outside="close"
  >
    <v-card
      v-if="dialog"
      style="background: #f5f5f5"
    >
      <v-card-text class="pa-0">
        <v-row class="pa-0 ma-0">
          <v-col
            v-for="(transfer, id) in transfers"
            :key="id"
            cols="12"
            class="pr-0 pt-0 pb-0"
          >
            <v-card
              class="elevation-2 mr-3 mt-3"
            >
              <v-card-title
                class="text-overline pa-0 pt-1 pl-2 pr-2 pb-2"
                style="font-size: 12px !important;"
              >
                <span>
                  <b>{{ transfer.model }}</b>
                </span>
                <span class="ml-2"> | {{ dateFormat(transfer.date, 'DD/MM HH:mm') }}</span>
              </v-card-title>

              <v-card-text>
                <v-row class="text-caption pl-2 pr-2">
                  <v-col
                    cols="6"
                    class="pa-0"
                  >
                    <b>{{ transfer.equipmentOrigin.plate }}</b>
                    <v-icon
                      small
                      class="mx-2 mb-1"
                      color="blue"
                    >
                      redo
                    </v-icon>
                    <b>{{ transfer.equipment.plate }} </b>
                  </v-col>
                  <v-col
                    cols="6"
                    class="pa-0 text-right"
                  >
                    Volume: {{ transfer.volume }} L<br>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-timeline
              align-top
              reverse
              dense
              class="ml-4"
            >
              <timeline-trailer-transfer-item
                v-for="(item, idx) in transfer.transfers"
                :key="idx"
                :item="item"
                :date="transfer.date"
              />
            </v-timeline>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TimelineTrailerTransferItem from "@/Domains/Itineraries/Components/TimelineTrailerTransferItem.vue";

import moment from "moment-timezone";

export default {

  components: {
    TimelineTrailerTransferItem
  },

  data() {
    return {
      dialog: false,
      transfers: [],
    }
  },

  methods: {

    async show(transfers) {
      this.transfers = transfers;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    dateFormat: (value, opts) => moment(value).format(opts),
  },
}
</script>
