<template>
  <div>
    <div
      v-if="loading || saving"
      class="d-flex flex-column pa-4 align-center"
    >
      {{ loading ? 'Carregando...' : 'Salvando...' }}
      <v-progress-linear
        color="white"
        indeterminate
        rounded
        height="6"
      />
    </div>
    <div
      v-else
      class="d-flex pa-3"
    >
      <v-badge
        :value="shipment.status !== 'PROCESSING'"
        color="green darken-1"
        icon="verified"
        overlap
        dark
        offset-x="11"
        offset-y="11"
        class="mr-3"
      >
        <v-tooltip
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              dark
              icon
              depressed
              v-on="on"
              @click.stop="onShipmentLoad()"
            >
              <v-icon>
                conveyor_belt
              </v-icon>
            </v-btn>
          </template>

          Carregamento
        </v-tooltip>
      </v-badge>

      <v-badge
        :value="shipment.status === 'FINISHED'"
        color="green darken-1"
        icon="verified"
        overlap
        dark
        offset-x="11"
        offset-y="11"
        class="mr-3"
      >
        <v-tooltip
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              dark
              icon
              depressed
              v-on="on"
              @click.stop="onShipmentPacking()"
            >
              <v-icon>
                inventory_2
              </v-icon>
            </v-btn>
          </template>

          Montagem da Carga
        </v-tooltip>
      </v-badge>

      <v-badge
        v-if="shipment.status !== 'FINISHED'"
        :value="false"
        color="green darken-1"
        icon="verified"
        overlap
        dark
        offset-x="11"
        offset-y="11"
        class="mr-3"
      >
        <v-tooltip
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              dark
              icon
              depressed
              v-on="on"
              @click.stop="onShowConclusionDialog()"
            >
              <v-icon>
                done_all
              </v-icon>
            </v-btn>
          </template>

          Concluir
        </v-tooltip>
      </v-badge>
    </div>
  </div>
</template>

<script>
import api from '@/Domains/Shipment/Api/Shipment.js'

import moment from 'moment'

export default {

  props: {
    shipment: {
      type: Object,
      default: () => ({}),
    },

  },

  data() {
    return {
      loading: false,
      saving: false,
    }
  },

  computed: {
    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasConcludeAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'expedicao-concluir' && o.tipo === 'COMPONENTE');
    },
  },

  methods: {

    async onShipmentLoad() {
      this.$emit('showShipmentLoad', this.shipment.id);
    },

    async onShipmentPacking() {
      this.$emit('showShipmentPacking', this.shipment.id);
    },

    async onShowConclusionDialog() {
      if (!(await this.$root.$confirm('Atenção', `Deseja concluir esta expedição?`, { color: 'green' }))) {
        return;
      }

      try {
        this.saving = true;

        const payload = { estado: 'FINALIZADO' }

        if (!this.shipment.operationTime) {
          payload.data_hora_descarga = moment().format('YYYY-MM-DD HH:mm:ss')
        }

        await api.update(this.shipment.id, payload);

        this.$snotify.success('Expedição finalizada com sucesso', 'Sucesso');
        this.reloadShipments();
      } catch (e) {
        this.$snotify.error('Erro ao atualizar expedição', 'Atenção');
        console.warn(e);
      } finally {
        this.saving = false;
      }
    },

    reloadShipments() {
      this.$emit('reloadShipments');
    },

  }
}
</script>
