<template>
  <div>
    <v-dialog
      v-model="show"
      persistent
      max-width="700px"
    >
      <v-card
        v-if="show"
        class="card-dialog"
      >
        <v-card-title>
          <span class="text-h6">
            Itinerário de Transbordo
          </span>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save"
          >
            <v-row>
              <v-col
                cols="3"
                class="pb-0"
              >
                <routes-autocomplete-filter
                  v-model="form.route"
                  label="Rota *"
                  type="coleta"
                  :hide-details="false"
                  :filled="false"
                  :rules="[v => !!v && !!v.id || 'Informe a rota']"
                />
              </v-col>

              <v-col
                cols="3"
                class="pb-0"
              >
                <vehicle-autocomplete-filter
                  v-model="form.vehicle"
                  label="Veículo *"
                  carga="leite"
                  placeholder=" "
                  :hide-details="false"
                  :filled="false"
                  :rules="[v => !!v && !!v.id || 'Informe o veículo']"
                />
              </v-col>

              <v-col
                cols="3"
                class="pb-0"
              >
                <person-autocomplete-filter
                  v-model="form.driver"
                  label="Motorista *"
                  type="DRIVER"
                  :hide-details="false"
                  :filled="false"
                  placeholder=" "
                  :rules="[v => !!v && !!v.id || 'Informe o motorista']"
                />
              </v-col>

              <v-col
                cols="3"
                class="pb-0"
              >
                <raw-material-autocomplete
                  v-model="form.rawMaterialId"
                  label="Matéria Prima *"
                  placeholder=" "
                  auto-select-raw-milk
                  disabled
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col
                cols="3"
                class="pb-0"
              >
                <masked-text-field
                  v-model="form.startedAt"
                  label="Início Itinerário *"
                  prepend-inner-icon="access_time"
                  placeholder=" "
                  :mask="{ mask: '00/00/0000 00:00' }"
                  :rules="[rules.required, rules.dateTime]"
                  :max-length="16"
                  validate-on-blur
                />
              </v-col>

              <v-col
                cols="3"
                class="pb-0"
              >
                <masked-text-field
                  v-model="form.endedAt"
                  label="Fim Itinerário *"
                  prepend-inner-icon="access_time"
                  placeholder=" "
                  :mask="{ mask: '00/00/0000 00:00' }"
                  :max-length="16"
                  :rules="[rules.required, rules.dateTime, endDateRule]"
                  validate-on-blur
                />
              </v-col>

              <v-col
                cols="3"
                class="pb-0"
              >
                <masked-text-field
                  v-model.number="form.initialKm"
                  label="Km Inicial *"
                  prepend-inner-icon="map"
                  placeholder=" "
                  :rules="[rules.required]"
                  :mask="{ mask: Number, min: 0, scale: 0, signed: false }"
                  unmask
                />
              </v-col>

              <v-col
                cols="3"
                class="pb-0"
              >
                <masked-text-field
                  v-model.number="form.finalKm"
                  label="Km Final *"
                  prepend-inner-icon="map"
                  placeholder=" "
                  :rules="[rules.required]"
                  :mask="{ mask: Number, min: 0, scale: 0, signed: false }"
                  unmask
                />
              </v-col>

              <v-col cols="12">
                <v-data-table
                  v-model="form.transfers"
                  :headers="transfers.headers"
                  :items="transfers.items"
                  disable-sort
                  hide-default-footer
                  class="elevation-1"
                  show-select
                  @input="onTransferChange"
                >
                  <template #[`item.volume`]="{ value }">
                    {{ formatNumber(value) }} L
                  </template>
                  <template #[`item.date`]="{ value }">
                    {{ formatDate(value, 'DD/MM HH:mm') }}
                  </template>
                </v-data-table>
                <v-input
                  :value="form.transfers.length"
                  required
                  :rules="[v => !!v || 'Selecione ao menos uma transferência']"
                />
              </v-col>
            </v-row>
          </v-form>
          <small>* Campo obrigatório</small>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="secondary"
            outlined
            @click="() => show = false"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            :disabled="loading || saving"
            :loading="saving"
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay
        v-model="loading"
        absolute
      >
        Carregando...
      </v-overlay>

      <v-overlay
        v-model="saving"
        absolute
      >
        Salvando...
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import RoutesAutocompleteFilter from '@/Support/Components/Filters/RoutesAutocompleteFilter.vue'
import VehicleAutocompleteFilter from '@/Support/Components/Filters/VehicleAutocompleteFilter.vue'
import PersonAutocompleteFilter from '@/Support/Components/Filters/PersonAutocompleteFilter.vue'
import RawMaterialAutocomplete from '@/Domains/Itineraries/Components/RawMaterialAutocomplete.vue'
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'

import moment from 'moment'

export default {

  components: {
    RoutesAutocompleteFilter,
    VehicleAutocompleteFilter,
    PersonAutocompleteFilter,
    RawMaterialAutocomplete,
    MaskedTextField
  },

  props: {
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      loading: false,

      saving: false,

      input: null,

      form: {
        route: {},
        vehicle: {},
        driver: {},
        startedAt: null,
        endedAt: null,
        rawMaterialId: null,
        initialKm: null,
        finalKm: null,
        transfers: [],
      },

      transfers: {
        headers: [
          { text: 'Origem', value: 'equipmentOrigin.plate' },
          { text: 'Destino', value: 'equipment.plate' },
          { text: 'Volume', value: 'volume' },
          { text: 'Data', value: 'date', width: 100 },
        ],
        items: [],
      },

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        dateTime: v => !v || (v.length == 16 && moment(v, 'DD/MM/YYYY HH:mm').isValid()) || 'Data Inválida!',
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.form = {
          route: {},
          vehicle: {},
          driver: {},
          startedAt: moment().format('DD/MM/YYYY HH:mm'),
          endedAt: moment().add(1, 'minutes').format('DD/MM/YYYY HH:mm'),
          rawMaterialId: null,
          initialKm: null,
          finalKm: null,
          transfers: [],
        }
        this.$refs.form?.resetValidation()
        this.loadTransfers()
      }
    }
  },

  methods: {
    async loadTransfers() {
      try {
        this.loading = true

        const { data } = await this.$axios.get(`/itinerario/transferenciasSemAcoplamento`)

        this.transfers.items = data.map(transfer => ({
          id: transfer.id,
          model: transfer.tipo,
          groupId: transfer.id_agrupamento,
          itineraryId: transfer.id_itinerario,
          date: transfer.data_hora_atualizacao,
          equipmentOrigin: {
            id: transfer.id_equipamento_origem,
            plate: transfer.placa_origem,
          },
          equipment: {
            id: transfer.id_equipamento_destino,
            plate: transfer.placa_destino,
          },
          volume: transfer.volume,
          transfers: transfer.transferencias
        }))
      } catch (err) {
        this.$snotify.error(
          'Oops, ocorreu um erro ao carregar os spots!',
          'Atenção'
        )
        console.warn(err)
      } finally {
        this.loading = false
        this.$refs.form?.resetValidation()
      }
    },

    async save() {
      if (!this.$refs.form.validate()) {
        return
      }

      try {
        this.saving = true

        const itineraryId = await this.storeItinerary()

        await this.saveTransfers(itineraryId)

        this.$emit('newItinerary', { id: itineraryId })

        this.show = false
      } catch (error) {
        this.$snotify.error('Oops, ocorreu um erro ao salvar o itinerário!', 'Atenção')
        console.warn(error)
      } finally {
        this.saving = false
      }
    },

    async storeItinerary() {
      const payload = {
        tipo: 'coleta',
        id_rota: this.form.route.id,
        descricao_rota: this.form.route.description,
        id_equipamento: this.form.vehicle.id,
        placa: this.form.vehicle.plate,
        veiculo: this.form.vehicle.description,
        id_pessoa: this.form.driver.id,
        nome_pessoa: this.form.driver.name,
        data_hora_inicio: moment(this.form.startedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'),
        data_hora_fim: moment(this.form.endedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'),
        data_hora_descarga: moment(this.form.endedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'),
        id_materia_prima: this.form.rawMaterialId,
        km_inicial: this.form.initialKm,
        km_final: this.form.finalKm,
      }

      const { data } = await this.$axios.post(
        `/itinerario/salva`,
        this.$qs.stringify(payload),
      )

      if (!data.codigo) {
        throw new Error(data.mensagem || data)
      }

      return data.id_itinerario
    },

    async saveTransfers(itineraryId) {
      const payload = {
        id_itinerario_destino: itineraryId,
        tipo: 'ACOPLAR',
        modelo: 'TRANSBORDO',
        transferencias: this.form.transfers.map(transfer => ({
          id: transfer.id,
          id_itinerario: transfer.itineraryId,
          id_equipamento_destino: this.form.vehicle.id,
          placa_destino: this.form.vehicle.plate,
        })),
      }

      const { data } = await this.$axios.post(
        `/itinerario/acoplarReboque`,
        payload
      )

      if (!data.codigo) {
        throw new Error(data.mensagem || data)
      }
    },

    onTransferChange(value) {
      if (!value?.length || value[0].model === 'REBOQUE') {
        return
      }

      this.form.vehicle = { ...value[0].equipment }
    },

    endDateRule(v) {
      if (!v || v.length !== 16) {
        return true
      }

      const endedAt = moment(v, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
      const startedAt = moment(this.form.startedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')

      if (endedAt < startedAt) {
        return 'A data final é menor que a inicial!'
      }

      return true
    },

    formatNumber: (value) => value ? new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value) : '-',
    formatDate: (value, format) => value ? moment(value).format(format) : '-'
  },
}
</script>
