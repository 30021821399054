<template>
  <div>
    <v-dialog
      v-model="visible"
      width="900"
      scrollable
      persistent
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title>
          Nova Análise
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="form">
            <v-row>
              <v-col
                md="6"
                class="pb-0"
              >
                <v-autocomplete
                  v-model="producer.item"
                  label="Produtor"
                  prepend-inner-icon="person_outline"
                  :items="producer.list"
                  item-text="nome"
                  item-value="id"
                  :rules="[v => !!v ||'Campo obrigatorio']"
                  clearable
                  return-object
                  :filter="(item, queryText, itemText) => (`${item.code} - ${item.nome.toLocaleLowerCase()}`).indexOf(queryText.toLocaleLowerCase()) > -1"
                  :loading="producer.loading"
                >
                  <template #[`item`]="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.code? `${item.code} -`:'' }}  {{ item.nome }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template #[`selection`]="{ item }">
                    <v-list-item-title>
                      {{ item.code? `${item.code} -`:'' }} {{ item.nome }}
                    </v-list-item-title>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                md="3"
                class="pb-0"
              >
                <v-menu
                  v-model="analysisAtMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  content-class="menu-select"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="analysisAt"
                      label="Data da análise*"
                      prepend-inner-icon="event"
                      readonly
                      v-bind="attrs"
                      clearable
                      :rules="[v => !!v ||'Campo obrigatorio']"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="datePicker.date"
                    @input="analysisAtMenu = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                md="3"
                class="pb-0"
              >
                <v-select
                  v-model="sampleType"
                  :items="['Subsequente', 'Semestral', 'Trimestral']"
                  label="Tipo de amostra"
                  prepend-inner-icon="change_circle"
                  clearable
                />
              </v-col>
            </v-row>

            <v-card outlined>
              <analysis
                v-if="!!analysis.acidity"
                v-model="analysis"
                :visible="visibleAnalysisParams"
                auto-mark-out-of-range
                @onAutoMarkOutOfRange="onAutoMarkOutOfRange"
              />
            </v-card>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="closeDialog"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="createRoutineAnalysis"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as _ from "lodash";
import moment from "moment";

import Analysis from "@/Domains/Platform/Unload/Components/Analysis.vue";

import AnalysisDictionary from "@/Domains/Platform/Unload/Services/AnalysisDictionary.js";

const AnalysisDict = _.invert(AnalysisDictionary);

export default {
  components: {
    Analysis
  },

  props: {
    analysisParameters: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      // flag do dialog
      visible: false,

      analysisAtMenu: false,

      datePicker: {
        date: null
      },

      sampleType: null,

      producer: {
        item: null,
        list: [],
        loading: false,
      },

      // lista de analises
      analysis: {},

      status: 'DENTRO_PADRAO',
      problems: [],
    };
  },

  computed: {
    analysisAt() {

      if (!this.datePicker.date) {
        return null;
      }

      return moment(this.datePicker.date).format("DD/MM/YYYY");
    },

    visibleAnalysisParams() {
      if (!this.analysisParameters?.length) {
        return [
          'acidity',
          'cryoscopy',
          'waterPercentage',
          'waterLiters',
          'density',
          'esd',
          'fat',
          'protein',
          'ph',
          'alizarol',
          'totalSolids',
          'brix',
          'est',
          'lactose',
          'sng',
          'mastitis',
          'chlorides',
          'betaLactams',
          'tetracycline',
          'antibiotic',
          'sulphonamide',
          'sodiumHydroxide',
          'bicarbonateOfSoda',
          'formol',
          'peroxide',
          'chlorine',
          'ureia',
          'starch',
          'sucrose',
          'neutralising',
          'restoratives',
          'conservatives',
          'sensorial',
          'quinolones',
          'aminoglykosides',
          'macrolides',
          'anfenicois',
          'alkalinePhosphatase',
          'alcohol',
          'clumps',
          'clots',
          'peroxidase',
          'solubilidade',
          'base_seca',
          'neomicina',
          'observation',
        ];
      }

      const hidden = ['numero_lacre', 'temp', 'otherSpecies', 'cefalosporina', 'others'];

      return this.analysisParameters
        .map(({ name }) => name)
        .filter(name => !hidden.includes(name));
    },
  },

  watch: {
    visible(value) {
      if (value) {
        this.analysisAtMenu = false;

        this.datePicker.date = null;

        this.producer = {
          item: null,
          list: [],
          loading: false,
        };

        this.status = 'DENTRO_PADRAO';
        this.problems = [];

        const settings = _.keyBy(this.analysisParameters, 'name');

        const getAnalysisRule = (param, field = 'rule') => {
          return _.get(settings, `${param}.${field}`, null);
        }

        this.analysis = {
          // temp: {
          //   value: null,
          //   rule: getAnalysisRule('temp'),
          //   restrictionRule: getAnalysisRule('temp', 'restrictionRule'),
          //   unit: `ºC`,
          //   decimals: 1,
          // },
          acidity: {
            value: null,
            rule: getAnalysisRule('acidity'),
            restrictionRule: getAnalysisRule('acidity', 'restrictionRule'),
            unit: getAnalysisRule('acidity', 'unit') || `ºD`,
            decimals: 3,
          },
          cryoscopy: {
            value: null,
            rule: getAnalysisRule('cryoscopy'),
            restrictionRule: getAnalysisRule('cryoscopy', 'restrictionRule'),
            unit: getAnalysisRule('cryoscopy', 'unit') || `ºC`,
            decimals: 3,
          },
          waterPercentage: {
            value: null,
            rule: getAnalysisRule('waterPercentage'),
            restrictionRule: getAnalysisRule('waterPercentage', 'restrictionRule'),
            decimals: 2,
          },
          waterLiters: {
            value: null,
            rule: getAnalysisRule('waterLiters'),
            restrictionRule: getAnalysisRule('waterLiters', 'restrictionRule'),
            decimals: 2,
          },
          density: {
            value: null,
            rule: getAnalysisRule('density'),
            restrictionRule: getAnalysisRule('density', 'restrictionRule'),
            unit: getAnalysisRule('density', 'unit') || `g/L`,
            decimals: 1,
          },
          fat: {
            value: null,
            rule: getAnalysisRule('fat'),
            restrictionRule: getAnalysisRule('fat', 'restrictionRule'),
            unit: getAnalysisRule('fat', 'unit') || `%/100g`,
            decimals: 3,
          },
          esd: {
            value: null,
            rule: getAnalysisRule('esd'),
            restrictionRule: getAnalysisRule('esd', 'restrictionRule'),
            unit: `g/100g`,
            decimals: 2,
          },
          est: {
            value: null,
            rule: getAnalysisRule('est'),
            restrictionRule: getAnalysisRule('est', 'restrictionRule'),
            decimals: 2,
          },
          protein: {
            value: null,
            rule: getAnalysisRule('protein'),
            restrictionRule: getAnalysisRule('protein', 'restrictionRule'),
            unit: `g/100g`,
            decimals: 2,
          },
          ph: {
            value: null,
            rule: getAnalysisRule('ph'),
            restrictionRule: getAnalysisRule('ph', 'restrictionRule'),
            unit: ``,
            decimals: 2,
          },
          alizarol: {
            value: null,
            rule: getAnalysisRule('alizarol'),
            restrictionRule: getAnalysisRule('alizarol', 'restrictionRule'),
            unit: getAnalysisRule('alizarol', 'unit') || `°GL`,
            decimals: 0,
          },
          totalSolids: {
            value: null,
            rule: getAnalysisRule('totalSolids'),
            restrictionRule: getAnalysisRule('totalSolids', 'restrictionRule'),
            decimals: 2,
          },
          brix: {
            value: null,
            rule: getAnalysisRule('brix'),
            restrictionRule: getAnalysisRule('brix', 'restrictionRule'),
            decimals: 2,
          },
          lactose: {
            value: null,
            rule: getAnalysisRule('lactose'),
            restrictionRule: getAnalysisRule('lactose', 'restrictionRule'),
            decimals: 2,
          },
          reductase: {
            value: null,
            rule: getAnalysisRule('reductase'),
            restrictionRule: getAnalysisRule('reductase', 'restrictionRule'),
          },

          // Inconformidades (Checkbox)
          alizarolQualit: {
            value: null,
            rule: getAnalysisRule('alizarolQualit'),
            restrictionRule: getAnalysisRule('alizarolQualit', 'restrictionRule'),
          },
          mastitis: {
            value: null,
            rule: getAnalysisRule('mastitis'),
            restrictionRule: getAnalysisRule('mastitis', 'restrictionRule'),
          },
          sensorial: {
            value: null,
            rule: getAnalysisRule('sensorial'),
            restrictionRule: getAnalysisRule('sensorial', 'restrictionRule'),
            hint: `Característico`,
          },
          alcohol: {
            value: null,
            rule: getAnalysisRule('alcohol'),
            restrictionRule: getAnalysisRule('alcohol', 'restrictionRule'),
          },
          alkalinePhosphatase: {
            value: null,
            rule: getAnalysisRule('alkalinePhosphatase'),
            restrictionRule: getAnalysisRule('alkalinePhosphatase', 'restrictionRule'),
          },
          clumps: {
            value: null,
            rule: getAnalysisRule('clumps'),
            restrictionRule: getAnalysisRule('clumps', 'restrictionRule'),
          },
          clots: {
            value: null,
            rule: getAnalysisRule('clots'),
            restrictionRule: getAnalysisRule('clots', 'restrictionRule'),
          },
          peroxidase: {
            value: null,
            rule: getAnalysisRule('peroxidase'),
            restrictionRule: getAnalysisRule('peroxidase', 'restrictionRule'),
          },
          // otherSpecies: {
          //   value: null,
          //   rule: getAnalysisRule('otherSpecies'),
          //   restrictionRule: getAnalysisRule('otherSpecies', 'restrictionRule'),
          // },

          // Antibióticos
          antibiotic: {
            value: null,
            rule: getAnalysisRule('antibiotic'),
            restrictionRule: getAnalysisRule('antibiotic', 'restrictionRule'),
          },
          betaLactams: {
            value: null,
            rule: getAnalysisRule('betaLactams'),
            restrictionRule: getAnalysisRule('betaLactams', 'restrictionRule'),
          },
          tetracycline: {
            value: null,
            rule: getAnalysisRule('tetracycline'),
            restrictionRule: getAnalysisRule('tetracycline', 'restrictionRule'),
          },
          sulphonamide: {
            value: null,
            rule: getAnalysisRule('sulphonamide'),
            restrictionRule: getAnalysisRule('sulphonamide', 'restrictionRule'),
          },
          quinolones: {
            value: null,
            rule: getAnalysisRule('quinolones'),
            restrictionRule: getAnalysisRule('quinolones', 'restrictionRule'),
          },
          aminoglykosides: {
            value: null,
            rule: getAnalysisRule('aminoglykosides'),
            restrictionRule: getAnalysisRule('aminoglykosides', 'restrictionRule'),
          },
          macrolides: {
            value: null,
            rule: getAnalysisRule('macrolides'),
            restrictionRule: getAnalysisRule('macrolides', 'restrictionRule'),
          },
          anfenicois: {
            value: null,
            rule: getAnalysisRule('anfenicois'),
            restrictionRule: getAnalysisRule('anfenicois', 'restrictionRule'),
          },
          neomicina: {
            value: null,
            rule: getAnalysisRule('neomicina'),
            restrictionRule: getAnalysisRule('neomicina', 'restrictionRule'),
          },
          // cefalosporina: {
          //   value: null,
          //   rule: getAnalysisRule('cefalosporina'),
          //   restrictionRule: getAnalysisRule('cefalosporina', 'restrictionRule'),
          // },

          // Neutralizantes
          neutralising: {
            value: null,
            rule: getAnalysisRule('neutralising'),
            restrictionRule: getAnalysisRule('neutralising', 'restrictionRule'),
            hint: `Ausência`,
          },
          sodiumHydroxide: {
            value: null,
            rule: getAnalysisRule('sodiumHydroxide'),
            restrictionRule: getAnalysisRule('sodiumHydroxide', 'restrictionRule'),
          },
          bicarbonateOfSoda: {
            value: null,
            rule: getAnalysisRule('bicarbonateOfSoda'),
            restrictionRule: getAnalysisRule('bicarbonateOfSoda', 'restrictionRule'),
          },

          // Reconstituintes
          restoratives: {
            value: null,
            rule: getAnalysisRule('restoratives'),
            restrictionRule: getAnalysisRule('restoratives', 'restrictionRule'),
            hint: `Ausência`,
          },
          sucrose: {
            value: null,
            rule: getAnalysisRule('sucrose'),
            restrictionRule: getAnalysisRule('sucrose', 'restrictionRule'),
          },
          starch: {
            value: null,
            rule: getAnalysisRule('starch'),
            restrictionRule: getAnalysisRule('starch', 'restrictionRule'),
          },
          chlorides: {
            value: null,
            rule: getAnalysisRule('chlorides'),
            restrictionRule: getAnalysisRule('chlorides', 'restrictionRule'),
          },
          chlorine: {
            value: null,
            rule: getAnalysisRule('chlorine'),
            restrictionRule: getAnalysisRule('chlorine', 'restrictionRule'),
          },
          ureia: {
            value: null,
            rule: getAnalysisRule('ureia'),
            restrictionRule: getAnalysisRule('ureia', 'restrictionRule'),
          },

          // Conservantes
          conservatives: {
            value: null,
            rule: getAnalysisRule('conservatives'),
            restrictionRule: getAnalysisRule('conservatives', 'restrictionRule'),
            hint: `Ausência`,
          },
          peroxide: {
            value: null,
            rule: getAnalysisRule('peroxide'),
            restrictionRule: getAnalysisRule('peroxide', 'restrictionRule'),
          },
          formol: {
            value: null,
            rule: getAnalysisRule('formol'),
            restrictionRule: getAnalysisRule('formol', 'restrictionRule'),
          },
          alkali: {
            value: null,
            rule: getAnalysisRule('alkali'),
            restrictionRule: getAnalysisRule('alkali', 'restrictionRule'),
          },
          solubilidade: {
            value: null,
            rule: getAnalysisRule('solubilidade'),
            restrictionRule: getAnalysisRule('solubilidade', 'restrictionRule'),
          },
          base_seca: {
            value: null,
            rule: getAnalysisRule('base_seca'),
            restrictionRule: getAnalysisRule('base_seca', 'restrictionRule'),
          },
          observation: {
            value: null,
            rule: getAnalysisRule('observation'),
            restrictionRule: getAnalysisRule('observation', 'restrictionRule'),
          },

          // Outras inconformidades
          // others: {
          //   value: null,
          //   rule: getAnalysisRule('others'),
          //   restrictionRule: getAnalysisRule('others', 'restrictionRule'),
          // },
        };
      }
    }
  },
  methods: {
    openDialog() {
      this.visible = true;
      this.loadProducer();
    },
    closeDialog() {
      this.visible = false;
    },

    /**
     * @void
     * Carrega a lista de produtores
     */
    async loadProducer() {
      try {
        this.producer.loading = true;
        const { data } = await this.$axios.get(`/produtores/listaProdutoresAtivos`);

        this.producer.list = data.map(producer => ({
          id: producer.id_pessoa,
          nome: producer.nome,
          code: producer.codigo_laticinio
        }));
      } catch (error) {
        this.producer.loading = false;
      } finally {
        this.producer.loading = false;
      }
    },

    async createRoutineAnalysis() {
      if (!this.$refs.form.validate()) {
        this.$snotify.error("Existem Campos obrigatórios não preenchidos!", "Atenção");
        return true;
      }
      try {
        this.$root.$progressBar.saving();

        await this.$axios.post(
          `/laboratorioAnalise/insereAnaliseRotina`,
          {
            'data': this.datePicker.date,
            id_produtor: this.producer.item.id,
            tipo_amostra: this.sampleType,
            estado: this.status,
            lista_inconformidades: this.problems,
            //temperatura: this.analysis.temp.value,
            acidez: this.analysis.acidity.value || 0,
            crioscopia: this.analysis.cryoscopy.value || 0,
            porcentagem_agua: this.analysis.waterPercentage.value || 0,
            volume_agua: this.analysis.waterLiters.value || 0,
            densidade: this.analysis.density.value || 0,
            esd: this.analysis.esd.value || 0,
            gordura: this.analysis.fat.value || 0,
            proteina: this.analysis.protein.value || 0,
            ph: this.analysis.ph.value || 0,
            alizarol: this.analysis.alizarol.value || 0,
            antibiotico: this.analysis.antibiotic.value,
            betalactamico: this.analysis.betaLactams.value,
            tetraciclina: this.analysis.tetracycline.value,
            sulfonamida: this.analysis.sulphonamide.value,
            cloretos: this.analysis.chlorides.value,
            mastite: this.analysis.mastitis.value,
            soda: this.analysis.sodiumHydroxide.value,
            bicarbonato: this.analysis.bicarbonateOfSoda.value,
            formol: this.analysis.formol.value,
            peroxido: this.analysis.peroxide.value,
            cloro: this.analysis.chlorine.value,
            ureia: this.analysis.ureia.value,
            amido: this.analysis.starch.value,
            sacarose: this.analysis.sucrose.value,
            neutralizantes: this.analysis.neutralising.value,
            reconstituintes: this.analysis.restoratives.value,
            conservantes: this.analysis.conservatives.value,
            sensorial: this.analysis.sensorial.value,
            quinolonas: this.analysis.quinolones.value,
            aminoglicosideos: this.analysis.aminoglykosides.value,
            macrolideos: this.analysis.macrolides.value,
            anfenicois: this.analysis.anfenicois.value,
            fosfatase: this.analysis.alkalinePhosphatase.value,
            solidos_totais: this.analysis.totalSolids.value || 0,
            brix: this.analysis.brix.value || 0,
            est: this.analysis.est.value || 0,
            alizarol_qualit: this.analysis.alizarolQualit.value,
            alcool: this.analysis.alcohol.value,
            lactose: this.analysis.lactose.value || 0,
            grumos: this.analysis.clumps.value,
            coagulos: this.analysis.clots.value,
            // sng: this.analysis.sng,
            redutase: this.analysis.reductase.value,
            alcalino: this.analysis.alkali.value,
            peroxidase: this.analysis.peroxidase.value,
            solubilidade: this.analysis.solubilidade.value,
            base_seca: this.analysis.base_seca.value,
            neomicina: this.analysis.neomicina.value,
            observacao: this.analysis.observation.value,
          }
        );

        this.$snotify.success("Analise de rotina salva com sucesso!", "Sucesso");

        this.closeDialog();
        this.$emit("onSave");

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar a análise!", "Atenção");
        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    onAutoMarkOutOfRange: _.debounce(function (fields) {
      this.problems = fields.problems.map(field => ({
        campo: AnalysisDict[field],
        padrao: this.analysis[field].rule,
        restricao: this.analysis[field].restrictionRule,
        valor: this.analysis[field].value,
      }));

      if (fields.discard) {
        this.status = 'FORA_PADRAO';
        return;
      }

      if (fields.restriction) {
        this.status = 'RESTRICAO';
        return;
      }

      this.status = 'DENTRO_PADRAO';
    }, 1000),
  },
};
</script>
