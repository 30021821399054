<template>
  <v-container>
    <v-row>
      <v-col
        class="py-0"
      >
        <date-text-field
          v-model="transferDate"
          label="Data de Transferência *"
          manual
          filled
          dark
          dense
          :rules="[v => !!v || 'Campo obrigatório!']"
        />
      </v-col>
      <v-col
        class="py-0"
      >
        <v-autocomplete
          v-model="originDairy"
          label="Unidade Origem *"
          :items="dairies"
          item-value="id"
          item-text="name"
          prepend-inner-icon="factory"
          return-object
          filled
          dark
          dense
          :disabled="isReady || isLoading || isSaving"
          :rules="[v => !!v || 'Campo obrigatório!']"
          @change="onOriginUnitChanged"
        />
      </v-col>
      <v-col
        class="py-0"
      >
        <v-autocomplete
          v-model="destinationDairy"
          label="Unidade Destino *"
          :items="destinationDairies"
          item-value="id"
          item-text="name"
          prepend-inner-icon="factory"
          return-object
          filled
          dark
          dense
          :disabled="!originDairy.id || isReady || isLoading || isSaving"
          :rules="[v => !!v || 'Campo obrigatório!']"
          @change="onDestinationUnitChanged"
        />
      </v-col>
      <v-col
        class="py-0"
      >
        <v-autocomplete
          v-model="originRoute"
          label="Rota Origem"
          :items="originRoutes"
          item-value="id"
          item-text="description"
          prepend-inner-icon="icon-rota"
          return-object
          dark
          dense
          filled
          clearable
          :disabled="isReady || isLoading || isSaving"
        >
          <template #item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.description }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="item.types.length">
                <v-chip
                  v-for="type in item.types"
                  :key="type"
                  x-small
                >
                  {{ type }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        class="py-0"
      >
        <v-autocomplete
          v-model="destinationRoute"
          label="Rota Destino"
          :items="destinationRoutes"
          item-value="id"
          item-text="description"
          prepend-inner-icon="icon-rota"
          return-object
          dark
          dense
          filled
          clearable
          :disabled="isReady || isLoading || isSaving"
        >
          <template #item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.description }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="item.types.length">
                <v-chip
                  v-for="type in item.types"
                  :key="type"
                  x-small
                >
                  {{ type }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        class="py-0"
      >
        <v-btn
          v-if="producers.length === 0"
          block
          elevation="1"
          x-large
          dark
          color="blue lighten-1"
          :disabled="!searchEnabled"
          :loading="isLoading"
          @click="search"
        >
          Buscar Produtores
          <v-icon
            right
            dark
            size="20"
          >
            send
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          block
          elevation="1"
          x-large
          dark
          color="red lighten-2"
          :loading="isSaving"
          @click="reset"
        >
          Cancelar
          <v-icon
            right
            dark
            size="20"
          >
            clear
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row style="position:relative">
      <v-overlay
        :value="!isReady || isLoading || isSaving"
        absolute
      >
        <v-card-text v-if="isLoading || isSaving">
          {{ isLoading ? 'Carregando...' : 'Salvando...' }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
        <v-alert
          v-else
          dense
          light
          prominent
          colored-border
          border="left"
          elevation="2"
          type="info"
        >
          Preencha os campos acima para iniciar a busca
        </v-alert>
      </v-overlay>

      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            Produtores
            <v-spacer />
            <v-col
              cols="4"
              md="3"
              class="py-0"
            >
              <v-text-field
                v-model="filter"
                append-icon="search"
                label="Filtrar"
                dense
                filled
                single-line
                hide-details
                dark
              />
            </v-col>
            <v-btn
              elevation="2"
              large
              color="green lighten-1"
              style="height:31px"
              :disabled="selecteds.length === 0"
              :loading="isSaving"
              @click="save"
            >
              Transferir
              <v-icon
                right
                dark
                size="20"
              >
                cloud_done
              </v-icon>
            </v-btn>
          </v-card-title>
          <data-table
            v-model="selecteds"
            :headers="headers"
            :items="producers"
            :search="filter"
            dark
            show-select
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';

import DateTextField from '@/Support/Components/DateTextField.vue';

export default {
  name: 'unit-change',

  components: {
    DateTextField,
  },

  data() {
    return {
      isLoading: false,
      isSaving: false,
      isReady: false,

      transferDate: moment().format('YYYY-MM-DD'),
      originDairy: {},
      destinationDairy: {},
      originRoute: {},
      destinationRoute: {},
      filter: null,

      selecteds: [],

      headers: [
        { text: 'Cód. Laticínio', value: 'code', drag: false },
        { text: 'Nome', value: 'name', drag: false },
        { text: 'Status', value: 'status', align: 'center', drag: false },
      ],
      items: [],

      dairies: [],
      producers: [],
      originRoutes: [],
      destinationRoutes: [],
    };
  },

  computed: {
    destinationDairies() {
      return this.dairies.filter(item => item.id !== this.originDairy.id);
    },

    searchEnabled() {
      if (!this.transferDate) {
        return false
      }

      if (!this.originDairy.id || !this.destinationDairy.id) {
        return false
      }

      if (this.originDairy.id === this.destinationDairy.id) {
        return false
      }

      return true
    },
  },

  mounted() {
    this.loadDairies();
  },

  methods: {
    async loadDairies() {
      try {
        const { data } = await this.$axios.post(`/pessoa/listaUnidadesLaticinio`, {
          filtrarLaticinio: false
        });

        this.dairies = data.map(item => ({
          id: item.id_pessoa,
          name: item.nome,
        }));
      } catch (error) {
        console.log(error);
        this.$snotify.error("Oops, ocorreu um erro ao carregar os laticínios!", "Atenção");
      }
    },

    onOriginUnitChanged() {
      if (!this.originDairy.id) {
        this.destinationDairy = {}
        this.producers = []
        this.originRoutes = []
        return
      }

      this.loadRoutes('ORIGIN')
    },

    onDestinationUnitChanged() {
      if (!this.destinationDairy.id) {
        this.originRoutes = []
        return
      }

      this.loadRoutes('DESTINATION')
    },

    async search() {
      try {
        this.isLoading = true;
        const { data } = await this.$axios.post(`/pessoa/listaProdutores`, {
          id_laticinio: this.originDairy.id,
          id_rota: this.originRoute.id,
        });

        this.producers = data.map(producer => ({
          id: producer.id_pessoa,
          code: producer.codigo_laticinio,
          name: producer.nome,
          status: producer.status,
        }));

        this.isReady = true;
      } catch (error) {
        console.log(error);
        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
      } finally {
        this.isLoading = false;
      }
    },

    async loadRoutes(type) {
      try {
        const { data } = await this.$axios.post(`/rota/listaRotaResumidaJson`, {
          tipo: 'coleta',
          id_laticinio: type === 'ORIGIN' ? this.originDairy.id : this.destinationDairy.id,
        });

        const routes = data.map(route => ({
          id: route.id_rota,
          description: route.descricao,
          types: route.tipos?.map(type => type.descricao?.toUpperCase()) || [],
        }));

        if (type === 'ORIGIN') {
          this.originRoutes = routes
        } else {
          this.destinationRoutes = routes
        }
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");
      }
    },

    async save() {
      if (!(await this.$root.$confirm(
        'Atenção',
        `Deseja realmente transferir os produtores selecionados?
        <br><br>
        - De: <b>${this.originDairy.name} ${this.originRoute.id ? ` - (${this.originRoute.description})` : ''}</b>
        <br>
        - Para: <b>${this.destinationDairy.name} ${this.destinationRoute.id ? ` - (${this.destinationRoute.description})` : ''}</b>
        <br><br>
        - Produtores: <b>${this.selecteds.length}</b>
        <br><br>
        <div class="v-alert v-sheet v-sheet--outlined theme--light elevation-0 v-alert--dense v-alert--outlined red--text text-body-2">
          Esta ação irá remover os produtores selecionados de todas as rotas de origem e adicionados apenas na rota do laticínio de destino.
        </div>
        Esta ação não poderá ser revertida!
        <br><br>`,
        { color: 'red', token: 'CONFIRMAR' }
      ))
      ) {
        return;
      }
      try {
        this.isSaving = true;

        const payload = {
          origin_dairy_id: this.originDairy.id,
          origin_dairy_name: this.originDairy.name,
          destination_dairy_id: this.destinationDairy.id,
          destination_dairy_name: this.destinationDairy.name,
          origin_route_id: this.originRoute.id,
          destination_route_id: this.destinationRoute.id,
          transfer_date: this.transferDate,
          producers: this.selecteds.map(o => o.id),
        };

        await this.$axios.post('/producer/transfer-unit', payload);

        this.$snotify.success("Sucesso, dados transferidos!");
      } catch (error) {
        const message = error?.response?.data?.message || 'Erro ao transferir dados'
        this.$snotify.error(message, 'Atenção')
        console.warn(error)
      } finally {
        this.reset();
        this.isSaving = false;
      }
    },

    reset() {
      this.isReady = false;
      this.isLoading = false;
      this.isSaving = false;
      this.transferDate = moment().format('YYYY-MM-DD');
      this.originDairy = {};
      this.destinationDairy = {};
      this.originRoute = {};
      this.destinationRoute = {};
      this.filter = null;
      this.producers = [];
      this.selecteds = [];
    },

    formatNumber: (value) => !value ? '' : new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? '' : moment(value).format(format)
  }

}
</script>
