<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-date-range-picker
                  v-model="filter.dateRange"
                  dark
                  @change="load"
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="filter.search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                  dark
                  filled
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            ref="report"
            :headers="headers"
            :items="items"
            :search="filter.search"
            :loading="loading"
            dark
          >
            <template #[`item.data_transferencia`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY') }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue"
          class="mr-5"
          v-on="on"
          @click="add()"
        >
          <v-icon>
            add
          </v-icon>
        </v-btn>
      </template>

      <span>Nova transferência</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import moment from 'moment-timezone';

import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';

export default {

  components: {
    VDateRangePicker,
  },

  data() {
    return {
      loading: false,

      filter: {
        search: '',
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },

      headers: [
        { text: 'Data', value: 'data_transferencia', align: 'start'  },
        { text: 'Produtor', value: 'produtor.nome', align: 'start'  },
        { text: 'Origem', value: 'laticinio_origem.nome', align: 'start'  },
        { text: 'Destino', value: 'laticinio_destino.nome', align: 'start'  },
      ],

      items: [],
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.filter.dateRange;

        const { data } = await this.$axios.get(`/producer/transfer-unit/history`, { params: {
          start_date: startDate,
          end_date: endDate,
        } });

        this.items = data;
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    add() {
      this.$emit('transfer');
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },
};
</script>
