<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        Transferência entre Depósitos
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="pt-5"
      >
        <v-form
          ref="formRef"
          lazy-validation
          @submit="save"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="pb-0"
            >
              <v-select
                v-model="form.warehouseOrigin"
                :items="warehouses"
                label="Depósito de Origem"
                item-value="id"
                item-text="description"
                return-object
                :rules="[v => !!v?.id || 'Campo obrigatório!']"
                @change="onWarehouseChange"
              >
                <template #item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.description }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.dairy }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pb-0"
            >
              <v-select
                v-model="form.warehouseDestination"
                :items="warehousesDestination"
                label="Depósito de Destino"
                item-value="id"
                item-text="description"
                return-object
                :disabled="!form.warehouseOrigin.id"
                :rules="[v => !!v?.id || 'Campo obrigatório!']"
              >
                <template #item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.description }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.dairy }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pb-0"
            >
              <v-autocomplete
                v-model="form.stock"
                label="Lote"
                :items="stocks.items"
                :loading="stocks.loading"
                item-value="id"
                item-text="lotNumber"
                return-object
                :disabled="!form.warehouseOrigin.id || !form.warehouseDestination.id"
                :rules="[v => !!v?.id || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pb-0"
            >
              <masked-text-field
                v-model="form.quantity"
                label="Quantidade *"
                :mask="masks.float"
                unmask
                :rules="[
                  v => !!v || 'Campo obrigatório!',
                  v => v > 0 || 'Quantidade inválida!',
                  v => v <= form.stock.quantity || 'Quantidade indisponível!',
                ]"
              />
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-textarea
                v-model="form.notes"
                label="Observações"
                rows="3"
                auto-grow
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, computed, reactive } from 'vue'

import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

import MaskedTextField from '@/Support/Components/MaskedTextField.vue'

const { progressBar, notify } = useUtils()

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
}

// eslint-disable-next-line
const emit = defineEmits([
  'save',
])

const dialog =  ref(false)

const formRef = ref()

const form = reactive({
  warehouseOrigin: {},
  warehouseDestination: {},
  stock: {},
  quantity: null,
  notes: null,
})

const warehouses = ref([])

const warehousesDestination = computed(() => {
  return warehouses.value.filter(w => w.id !== form.warehouseOrigin.id && w.dairyId === form.warehouseDestination.dairyId)
})

const product = ref({})

const close = () => {
  dialog.value = false
}

const show = (data) => {
  dialog.value = true

  product.value = data.product

  form.warehouseOrigin = {}
  form.warehouseDestination = {}
  form.stock = {}
  form.quantity = null
  form.notes = null

  formRef.value?.resetValidation()
}

const save = async () => {
  if (!await formRef.value?.validate()) {
    return
  }

  try {
    progressBar?.saving()

    const payload = {
      id_item: product.value.id,
      id_deposito_origem: form.warehouseOrigin.id,
      id_deposito_destino: form.warehouseDestination.id,
      lote: {
        numero: form.stock.lotNumber,
        agrupador: form.stock.grouping,
        data_fabricacao: form.stock.manufacturingDate,
        data_validade: form.stock.expirationDate,
        fornecedor: form.stock.supplier,
      },
      valor: form.stock.price,
      quantidade: form.quantity,
      observacao: form.notes,
    }

    await axios.post(`/stock/transfer`, payload)

    close()

    emit('save')

    notify.success('Estoque transferido com sucesso', 'Sucesso')
  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao transferir estoque'
    notify.error(message, 'Atenção')
    console.warn(error)
  } finally {
    progressBar?.hide()
  }
}

const loadWarehouses = async () => {
  try {
    progressBar?.loading()

    const { data } = await axios.get(`/warehouse`)

    warehouses.value = data.map(item => ({
      id: item.id_deposito,
      description: item.descricao,
      dairyId: item.id_laticinio,
      dairy: item.nome_laticinio,
    }))
  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao carregar depósitos'
    notify.error(message, 'Atenção')
    console.warn(error)
  } finally {
    progressBar?.hide()
  }
}

const stocks = reactive({
  loading: false,
  items: [],
})

const onWarehouseChange = () => {
  form.stock = {}
  form.warehouseDestination = {}
  loadStocks()
}

const loadStocks = async () => {
  stocks.items = []

  if (!form.warehouseOrigin.id) {
    return
  }

  try {
    stocks.loading = true

    const { data } = await axios.get(`/stock`, { params: {
      ids: [product.value.id],
      id_deposito: form.warehouseOrigin.id,
    } })

    stocks.items = data.map(item => ({
      id: item.id_estoque,
      lotNumber: item.numero_lote,
      grouping: item.agrupador,
      manufacturingDate: item.data_fabricacao,
      expirationDate: item.data_validade,
      quantity: item.quantidade,
      supplier: {
        id: item.id_fornecedor,
        name: item.nome_fornecedor,
      },
      price: item.valor,
    }))
  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao carregar estoques'
    notify.error(message, 'Atenção')
    console.warn(error)
  } finally {
    stocks.loading = false
  }
}

// eslint-disable-next-line
defineExpose({
  show
})

loadWarehouses()
</script>
