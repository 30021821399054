<template>
  <div class="mx-6 px-16 pt-0 pb-7">
    <v-flex
      class="text-xs-center mb-4"
      xs12
    >
      <h2 class="menu-header white--text">
        Visitas - {{module.toUpperCase()}}
      </h2>

      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <v-card
      class="visits-list"
      dark
    >
      <v-card-title class="pb-0">
        <v-layout row>
          <v-col
            class="pb-0"
            md-3
          >
            <v-date-range-picker
              v-model="filters.dateRange"
              :ranges="dateRanges"
              format="DD/MM/YYYY"
              @change="onDateFilter"
            />
          </v-col>
          <v-col
            class="pb-0"
            md-3
          >
            <v-autocomplete
              v-model="filters.visitsTypes"
              :items="visitsTypes"
              clearable
              filled
              label="Tipo de Visita"
              multiple
              prepend-inner-icon="menu"
              @change="loadVisits"
            />
          </v-col>
          <v-col
            class="pb-0"
            md-3
          >
            <v-autocomplete
              v-model="filters.type"
              :items="types"
              clearable
              filled
              label="Tipo"
              multiple
              prepend-inner-icon="menu"
              @change="loadVisits"
            />
          </v-col>
          <v-col
            class="pb-0"
            md-3
          >
            <v-autocomplete
              v-model="filters.signature"
              :items="signatures"
              clearable
              filled
              label="Assinatura"
              prepend-inner-icon="label"
              @change="loadVisits"
            />
          </v-col>
          <v-col
            class="pb-0"
            md-3
          >
            <v-autocomplete
              v-model="filters.route"
              :items="routes"
              clearable
              filled
              item-text="description"
              item-value="id"
              label="Rota"
              multiple
              prepend-inner-icon="map"
              @change="loadVisits"
            />
          </v-col>
          <v-col
            class="pb-0"
            md-3
          >
            <person-autocomplete-filter
              v-model="filters.technical"
              label="Técnico"
              type="TECHNICAL"
              multiple
              @change="loadVisits"
            />
          </v-col>
          <v-col
            class="pb-0"
            md-3
          >
            <person-autocomplete-filter
              v-model="filters.producers"
              label="Produtor"
              type="PRODUCER"
              multiple
              @change="loadVisits"
            />
          </v-col>
          <v-col
            class="pb-0"
            md-3
          >
            <v-autocomplete
              v-model="filters.groups"
              :items="listGroupService"
              clearable
              filled
              item-text="nome"
              item-value="id_grupo_servico"
              label="Grupo"
              multiple
              prepend-inner-icon="map"
              :loading="loadingGroup"
              @change="loadFilterData"
            />
          </v-col>
          <v-col
            class="pb-0"
            md-3
          >
            <v-autocomplete
              v-model="filters.services"
              :items="listService"
              clearable
              filled
              item-text="descricao"
              item-value="id_servico"
              label="Serviço"
              placeholder="Selecione ao menos um grupo"
              multiple
              prepend-inner-icon="map"
              :loading="loadingService"
              @change="loadVisits"
            />
          </v-col>
        </v-layout>
      </v-card-title>
    </v-card>

    <v-data-table
      :headers="headers"
      :items="dataVisits"
      class="technical-visit-table "
      disable-pagination
      group-by="visit.date"
      hide-default-footer
      item-key="visit.id"
      show-expand
      single-expand
    >
      <template #expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          class="pa-0"
        >
          <v-data-table
            :headers="headersGrupoServicos"
            :items="item.grupos_servicos"
            class="visit-table technical-visit-table  ma-2"
            disable-pagination
            hide-default-footer
            item-key="id_servico"
            show-expand
            single-expand
          >
            <template #expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                class="pa-0"
              >
                <v-row>
                  <v-col
                    cols="6"
                  >
                    <v-data-table
                      :headers="headersGroupServicesCauses"
                      :items="item.causas ?? []"
                      class="technical-visit-table ma-3"
                      disable-pagination
                      hide-default-footer
                      item-key="id_causa"
                    >
                    </v-data-table>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-data-table
                      :headers="headersGroupServicesRecomendations"
                      :items="item.recomendacoes ?? []"
                      class="technical-visit-table ma-3"
                      disable-pagination
                      hide-default-footer
                      item-key="id_recomendacao"
                    >
                    </v-data-table>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-data-table
                      :headers="headersGroupServicesIndicators"
                      :items="item.indicadores ?? []"
                      class="technical-visit-table ma-3"
                      disable-pagination
                      hide-default-footer
                      item-key="id_indicador"
                    >
                    </v-data-table>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-data-table
                      :headers="headersGroupServicesChecklists"
                      :items="item.checklists ?? []"
                      class="technical-visit-table ma-3"
                      disable-pagination
                      hide-default-footer
                      item-key="id_checklist_servico"
                      >
                      </v-data-table>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
          <v-layout
            v-if="Object.keys(item.photos).length"
            row
            class="ma-2"
          >
            <v-flex
              md12
              sm12
            >
              <v-card
                class="mt-3 technical-visit-card"
                dark
              >
                <v-card-title>
                  <span class="text-subtitle-2">Fotos</span>
                </v-card-title>

                <v-card-text
                  class="pt-6 pb-0"
                  style="background: rgba(255, 255, 255, 0.15)"
                >
                  <v-layout
                    class="mb-0"
                    row
                    wrap
                  >
                    <v-img
                      v-for="(image, index) in item.photos[0]"
                      :key="index"
                      :lazy-src="image"
                      class="mr-3 mb-3"
                      max-width="100"
                      min-height="133"
                      style="cursor: zoom-in !important;"
                      @click="openGallery(index,image)"
                    />
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </td>
      </template>
      <template #[`item.visitType`]="{ item }">
        <div
          v-for="(type, i) in item.visitType"
          :key="i"
        >
          <v-chip
            x-small
            color="green"
          >
            {{ type.tipo }}
          </v-chip>
        </div>
      </template>
      <template #[`item.visit.types`]="{ item }">
        <div
          v-for="(type, i) in item.visit.types"
          :key="i"
        >
          <v-chip
            v-if="type.text"
            x-small
          >
            {{ type.text }}
          </v-chip>
        </div>
      </template>
      <template #[`item.visit.location`]="{ item }">
        <v-btn
          v-if="item.visit.location.lat_ini"
          color="green lighten-2"
          dark
          icon
        >
          <v-icon>location_on</v-icon>
        </v-btn>
        <v-btn
          v-else
          color="red lighten-3"
          dark
          icon
        >
          <v-icon>location_off</v-icon>
        </v-btn>
      </template>
      <template #[`item.questionnaires`]="{ value }">
        <v-menu>
          <template #activator="{ on }">
            <v-btn
              v-if="value.length > 0"
              color="orange lighten-3"
              dark
              icon
              v-on="on"
            >
              <v-icon>receipt_long</v-icon>
            </v-btn>

            <v-btn
              v-else
              color="gray lighten-3"
              dark
              icon
              disabled
            >
              <v-icon>receipt_long</v-icon>
            </v-btn>
          </template>
          <v-list
            v-for="(questionnaire, index) in value"
            :key="index"
          >
            <v-list-item @click="showQuestionnaire(questionnaire)">
              <v-list-item-icon>
                <v-icon>receipt_long</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ questionnaire.titulo }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template #[`item.signature`]="{ item }">
        <template>
          <v-btn
            v-if="!item.visit.signature"
            color="red lighten-2"
            dark
            icon
          >
            <v-icon>assignment_late</v-icon>
          </v-btn>

          <v-btn
            v-else
            color="green lighten-2"
            dark
            icon
            @click="openSignatureDialog(item)"
          >
            <v-icon>assignment_turned_in</v-icon>
          </v-btn>
        </template>
      </template>
      <template #[`item.photos`]="{ item }">
        <v-btn
          v-if="Object.keys(item.photos).length > 0"
          color="green lighten-2"
          dark
          icon
        >
          <v-icon>image</v-icon>
        </v-btn>
        <v-btn
          v-if="Object.keys(item.photos).length === 0"
          color="gray lighten-2"
          dark
          icon
          disabled
        >
          <v-icon>image</v-icon>
        </v-btn>
      </template>
    </v-data-table>


    <v-speed-dial
      bottom
      class="mr-5"
      dark
      direction="top"
      fixed
      open-on-hover
      right
      transition="slide-y-reverse-transition"
    >
    </v-speed-dial>

  <v-dialog
    v-model="signatureDialog.show"
    max-width="400"
    @close="closeDialogs"
  >
  <v-card>
    <v-card-title class="text-subtitle-2">
      Assinatura do Produtor
    </v-card-title>
    <v-divider />
    <v-card-text>
      <img
        :src="signatureDialog.signature"
        alt="assinatura"
        width="100%"
      >
    </v-card-text>
  </v-card>
  </v-dialog>

  <v-speed-dial
    bottom
    class="mr-5"
    dark
    direction="top"
    fixed
    open-on-hover
    right
    transition="slide-y-reverse-transition"
  >
  <template #activator>
    <v-btn
      color="blue darken-2"
      dark
      fab
      large
    >
      <v-icon>menu</v-icon>
    </v-btn>
  </template>

  <v-btn
    color="orange darken-1"
    dark
    fab
    @click="showPrintDialog()"
  >
    <v-tooltip left>
      <template #activator="{ on }">
        <v-icon v-on="on">
          print
        </v-icon>
      </template>

      Imprimir
    </v-tooltip>
  </v-btn>

  </v-speed-dial>

  <print-settings-dialog
    ref="print-settings"
    @print="print"
  />
</div>
</template>

<style lang="scss">

.visit-table .v-data-table__wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
}

.visits-list {
  background: rgba(0, 0, 0, 0.3) !important;
}

.technical-visit-card {
  background: transparent !important;

  .v-card__title {
    background: rgba(0, 0, 0, .3);
  }
}

.technical-visit-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.2);
    }

    tbody {
      background: rgba(255, 255, 255, 0.15);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }

      .v-row-group__header {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }
}

</style>

<script>
import moment from "moment/moment";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import _ from "lodash";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import PrintSettingsDialog from "@/Support/Components/PrintSettingsDialog.vue";
import LightBox from 'vue-image-lightbox'
export default {
  name: "quality-indicators-visits",

  components: {
    VDateRangePicker,
    PersonAutocompleteFilter,
    LightBox,
    PrintSettingsDialog,
  },

  props: {
    module: String,
  },

  mixins: [ReportMixin],

  filters: {
    uppercase(value) {
      return (value || "").toUpperCase();
    },

    date(value) {
      if (_.isEmpty(value)) {
        return;
      }

      return moment(value).format('DD/MM/YYYY');
    }
  },

  data() {
    return {
      // Loaders
      loading: false,
      loadingService: false,
      loadingGroup: false,

      // Dialogo de confirmação
      confirmationDialog: {
        show: false,
        callback: () => {
        },
        title: '',
        content: '',
        item: {},
        typedToken: '',
        confirmationToken: '',
      },

      signatureDialog: {
        show: false,
        signature: '',
      },

      dateRanges: {
        'Hoje': [
          moment().startOf("day").format("YYYY-MM-DD"),
          moment().endOf("day").format("YYYY-MM-DD")
        ],
        'Ontem': [
          moment().subtract(1, "day").startOf("day").format("YYYY-MM-DD"),
          moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD")
        ],
        'Este Mês': [
          moment().startOf("month").format("YYYY-MM-DD"),
          moment().endOf("month").format("YYYY-MM-DD")
        ],
        'Mês Anterior': [
          moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
          moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
        ],
        'Últimas 100 Visitas': [null, null]
      },



      // Filtros usados para buscar a rota
      filters: {
        // Range de referência para a bonificação
        date: {
          input: "today",
          range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        },
        dateRange: [
          moment().startOf("day").format("YYYY-MM-DD"),
          moment().endOf("day").format("YYYY-MM-DD")
        ],

        groups: null,
        services: null,

        // Tipo de visita
        type: null,

        // Modelo de visita
        model: null,

        // Modelos de visita disponíveis no formulário
        models: [
          { text: 'Padrão', value: 'PADRAO' },
          { text: 'Prospecção', value: 'PROSPECCAO' },
          { text: 'Atendimento', value: 'ATENDIMENTO' },
          { text: 'Outros', value: 'OUTROS' },
        ],

        // Assinatura
        signature: null,

        // Rota filtrada para busca
        route: [],

        // Pessoa filtrada para a busca
        person: null,

        // Lista de pessoas disponíveis no formulário
        persons: [],

        // Campo para buscar pelo produtor
        producers: [],

        technical: [],

        visitsTypes: [],

        /**
         * Filtros do projeto
         */
        project: {
          // Lista de vigências
          validityList: [],

          // Vigência selecionada
          validity: [],

          // Filtro de data
          date: {
            range: [],

            min: moment().startOf('year').format('YYYY-MM'),
            max: moment().endOf('year').format('YYYY-MM'),
          }
        }
      },

      visitsTypes: [
        { text: "Visitas Definidas", value: "VISITA"},
        { text: "Agronômicas", value: "AGRONOMICO"},
        { text: "Veterinárias", value: "VETERINARIO"},
      ],

      allTypes: [
        { text: "Meta Laticinio", value: "PADRAO", type: "visits" },
        { text: "CPP", value: "CPP", type: "visits" },
        { text: "CCS", value: "CCS", type: "visits" },
        { text: "BPA", value: "BPA", type: "visits" },
        { text: "PROSPECÇÃO", value: "PROSPECCAO", type: "visits" },
        { text: "COLETA DE AMOSTRA", value: "COLETA_AMOSTRA", type: "visits" },
        { text: "VETERINÁRIO", value: "VETERINARIO", type: "visits" },
        { text: "ZOOTÉCNICO", value: "ZOO_TECNICO", type: "visits" },
        { text: "VENDA", value: "VENDER_PRODUTOS", type: "visits" },
        { text: "ACOMPANHAMENTO DE ORDENHA", value: "ACOMPANHAMENTO_DE_ORDENHA", type: "visits" },
        { text: "CRIOSCOPIA", value: "CRIOSCOPIA", type: "visits" },
        { text: "ANTIBIOTICO", value: "ANTIBIOTICO", type: "visits" },
        { text: "ALTERAÇÕES NO LEITE", value: "ALTERACOES_NO_LEITE", type: "visits" },
        { text: "ROTINA", value: "ROTINA", type: "visits" },
        { text: "PMLS", value: "PMLS", type: "visits" },
        { text: "Checklist Inicial", value: "CHECKLIST_INICIAL", type: "visits" },
        { text: "Brucelose / Tuberculose", value: "BRUCELOSE_TUBERCULOSE", type: "visits" },
        { text: "IN77", value: "IN77", type: "visits" },
        { text: "IN59", value: "IN59", type: "visits" },
        { text: "PQFL", value: "PQFL", type: "visits" },
      ],
      // Registro selecionado na lista
      selectedProducer: [],

      // Lista de produtores para geração da bonificação
      visits: [],

      listGroupService: [],
      listService: [],
      types: [],
      routes: [],
      signatures: [
        { text: 'Com Assinatura', value: '1' },
        { text: 'Sem Assinatura', value: '2' },
      ],

      dataVisits: [],

      headersGrupoServicos: [
        { text: 'Grupo', value: 'grupo', width: 250, },
        { text: 'Servico', value: 'servico' },
      ],

      headersGroupServicesCauses: [
        { text: 'Causa', value: 'descricao', width: 250, }
      ],

      headersGroupServicesRecomendations: [
        { text: 'Recomendações', value: 'descricao', width: 250, }
      ],

      headersGroupServicesIndicators: [
        { text: 'Indicadores', value: 'descricao', width: 250, }
      ],

      headersGroupServicesChecklists: [
        { text: 'Checklist', value: 'checklist', width: 250, },
        { text: 'Descricao', value: 'descricao', width: 250, },
      ],

      printParams: [],
    };
  },

  computed: {
    headers() {
      const headers =  [
        { align: 'start', text: 'Nome', value: 'producer.name', sortable: true },
        { align: 'center', text: 'Tipo Visita', value: 'visitType', sortable: true, width: 120 },
        { text: 'Início', value: 'visit.startedAt', width: 80, sortable: true },
        { text: 'Fim', value: 'visit.endedAt', width: 80, sortable: true },
        { text: 'Duração', value: 'visit.duration', width: 80, sortable: true },
      ];

      headers.push(
        { text: 'Tipos', value: 'visit.types', width: 120, sortable: true },
        { aling: 'end', text: '', value: 'visit.location', width: 80, sortable: true },
        { align: 'end', text: 'Questionários', value: 'questionnaires', width: 90, sortable: false },
        { align: 'end', text: 'Ass.', value: 'signature', width: 100, sortable: true },
        { align: 'end', text: 'Fotos', value: 'photos', width: 100, sortable: true },
        { align: 'end', text: '', value: 'actions', width: 60, sortable: true },
      );

      return headers;
    },
  },

  async mounted() {
    this.loadTypes();
    await this.loadGroupService();
    await this.loadRoutesFilter();

    // Aguarda o DOM do componente estar completamente renderizado
    this.$nextTick(() => {
      // Seleciona todos os botões que correspondem às classes
      const buttons = document.querySelectorAll(
        'button.ma-0.v-btn.v-btn--icon.v-btn--round.theme--dark.v-size--small'
      );

      buttons.forEach(button => {
        // Verifica se o botão possui o ícone "close"
        const icon = button.querySelector('.v-icon.material-icons');
        if (icon && icon.textContent.trim() === 'close') {
          // Oculta o botão
          button.style.display = 'none';
        }
      });
    });
  },

  methods: {
    onDateFilter(event) {
      this.filters.date.range = event;
      this.loadVisits();
    },

    async loadFilterData() {
      await this.loadVisits();
      await this.loadService();
    },

    async loadGroupService() {
      try {
        this.loadingGroup = true;
        const { data } = await this.$axios.get(`/projects/visits-defined/groups`, {
          params: {
            module: this.module,
          }
        });

        this.listGroupService = data.map(function(group){
          return {
            'id_grupo_servico': group.id_grupo_servico,
            'nome': group.nome.toUpperCase(),
          }
        });
      } catch (error) {
      this.$snotify.error("Oops, ocorreu um erro ao carregar os grupos!", "Atenção");
        console.warn(error);
      } finally {
        this.loadingGroup = false;
      }
    },

    async loadService() {
      try {

        this.loadingService = true;

        const { data } = await this.$axios.get(`/projects/visits-defined/services`, { params: {
            groups: this.filters.groups,
          } });

        this.listService = data.map(function(service){
          return {
            'id_servico': service.id_servico,
            'descricao': service.descricao.toUpperCase(),
          }
        });

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os serviços!", "Atenção");
        console.warn(error);
      } finally {
        this.loadingService = false;
      }
    },
    async loadVisits() {
      try {
        this.$root.$progressBar.loading();

        let [startDate, endDate] = this.filters.dateRange;

        const { data } = await this.$axios.get(`/projects/visits-defined`, { params: {
            module: this.module,
            startDate: startDate,
            endDate: endDate,
            type: this.filters.type,
            groups: this.filters.groups,
            services: this.filters.services,
            signatures: this.filters.signature,
            routes: this.filters.route,
            technicals: this.filters.technical,
            producers: this.filters.producers,
            visitType: this.filters.visitsTypes,
          } });

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        const visits = data.map(visit => {
          return {
            technical: {
              id: visit.id_tecnico,
              name: (visit.nome || '').toUpperCase(),
              signature: visit.assinatura_tecnico,
            },
            producer: {
              id: visit.id_produtor,
              name: (visit.nome_produtor || '').toUpperCase(),
              codLaticinio: visit.codigo_laticinio,
              signature: visit.assinatura_produtor,
            },
            visit: {
              id: visit.id_visita,
              date: moment(visit.data_visita).format('DD/MM/YYYY'),
              startedAt: moment(visit.hora_chegada, 'HH:mm:ss').format('HH:mm'),
              endedAt: moment(visit.hora_saida, 'HH:mm:ss').format('HH:mm'),
              duration: moment.utc(moment(visit.hora_saida, 'HH:mm:ss').diff(moment(visit.hora_chegada, 'HH:mm:ss'))).format('HH:mm'),
              observations: visit.observacao || '',
              signature: _.isEmpty(visit.assinatura) ? null : `data:image/png;base64,${visit.assinatura}`,
              types: this.allTypes.filter(type => visit.tipo.some(visitType => visitType === type.value)),
              location: {
                lat_ini: visit.end_lat_ini || '',
                lng_ini: visit.end_lng_ini || '',
              },
            },
            grupos_servicos: visit.servicos,
            photos: visit.anexos,
            questionnaires: visit.formularios || [],
            openRecommendations: visit.recomendacoes_abertas.map(recommendation => ({
              recommendation: recommendation.recomendacao,
              indicator: recommendation.indicador_afetado,
              cause: recommendation.causa,
              status: recommendation.status,
              obs: recommendation.observacao,
              statusDate: recommendation.data_conclusao ?  moment(recommendation.data_conclusao).format('DD/MM/YYYY') : null
            })),
            idAtendimento: visit.id_atendimento,
            visitType: visit.tipoVisita,
          }
        });

        this.dataVisits = visits;

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os serviços!", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    loadTypes() {
      switch (this.module) {
        case 'pqfl':
          this.types = [
            { text: "PAE", value: "PAE" },
            { text: "BPA", value: "BPA", type: "visits" },
            { text: "CHECKLIST INICIAL", value: "CHECKLIST_INICIAL", type: "visits" },
            { text: "BRUCELOSE / TUBERCULOSE", value: "BRUCELOSE_TUBERCULOSE", type: "visits" },
          ];
          break;
        case 'pmls':
          this.types = [
            { text: "PMLS", value: "PMLS", type: "visits" },
          ];
          break;
        case 'empresa':
          this.types = [
            { text: "META LATICÍNIO", value: "PADRAO", type: "visits" },
            { text: "PAE", value: "PAE" },
            { text: "BPA", value: "BPA", type: "visits" },
            { text: "PROSPECÇÃO", value: "PROSPECCAO", type: "visits" },
            { text: "COLETA DE AMOSTRA", value: "COLETA_AMOSTRA", type: "visits" },
            { text: "VETERINÁRIO", value: "VETERINARIO", type: "visits" },
            { text: "ZOOTÉCNICO", value: "ZOO_TECNICO", type: "visits" },
            { text: "VENDA", value: "VENDER_PRODUTOS", type: "visits" },
            { text: "ACOMPANHAMENTO DE ORDENHA", value: "ACOMPANHAMENTO_DE_ORDENHA", type: "visits" },
            { text: "CRIOSCOPIA", value: "CRIOSCOPIA", type: "visits" },
            { text: "ANTIBIOTICO", value: "ANTIBIOTICO", type: "visits" },
            { text: "ALTERAÇÕES NO LEITE", value: "ALTERACOES_NO_LEITE", type: "visits" },
            { text: "ROTINA", value: "ROTINA", type: "visits" },
          ];
          break;
        default:
          this.types = [];
      }
    },

    async loadRoutesFilter() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/rota/listaRotaResumidaJson`);

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.routes = data.map(route => {
          return {
            id: route.id_rota,
            description: route.descricao,
          }
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    openGallery(index, image) {
      window.open(image, '_blank');
    },

    openSignatureDialog(item) {
      this.signatureDialog.signature = item.visit.signature;
      this.signatureDialog.show = true;
    },

    closeDialogs() {
      this.signatureDialog.show = false;
    },

    showPrintDialog() {
      let params =  [
        { title: 'Faixa (Curva ABC)', key: 'mostra_curva_abc', value: true },
        { title: 'Resultados Qualidade', key: 'mostra_resultados_qualidade', value: true },
        { title: 'Médias Qualidade', key: 'mostra_medias_qualidade', value: true },
        { title: 'Coletas de amostras', key: 'mostra_coleta_amostras', value: true },
        { title: 'Documentos assinados', key: 'mostra_documentos_assinados', value: false },
        { title: 'Localização', key: 'mostra_localizacao', value: false },
        { title: 'Rota do produtor', key: 'mostra_rota', value: false },
      ]

      const visits = this.dataVisits;

      this.printParams = params;

      this.$refs['print-settings'].show({
        visits,
        params: params,
      });
    },

    async print() {
      try {
        this.$root.$progressBar.loading();

        const visits = this.dataVisits;

        const { data } = await this.$axios.post(`/projects/visits-defined/print`, {
          visits,
          params: this.printParams,
        });

        let fileName = '';

        fileName = `Visitas.pdf`;

        const binaryString = window.atob(data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const newBlob = new Blob([bytes], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);

          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const dataObject = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(data);
        link.remove();

      } catch (e) {
        console.log(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },
  },
};
</script>
