<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="filter.project"
          :items="projectList"
          label="Descrição"
          item-value="id"
          item-text="text"
          return-object
          hide-details
          clearable
          dark
          filled
          :rules="[v => !!v || 'Campo obrigatório']"
          @change="loadDateRange"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="filter.curve"
          dark
          filled
          return-object
          label="Classe"
          clearable
          :items="curveItem"
          @change="loadTabs"
        />
      </v-col>

      <v-col
        md="3"
      >
        <month-picker
          v-model="filter.date.input"
          disable-last-thirty
          not-clearable
          @change="onFilterDate"
        />
      </v-col>

      <v-col
        md="3"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          @change="onLoad"
        />
      </v-col>
      <v-col
        md="3"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          @change="onLoad"
        />
      </v-col>
      <v-col
        md="3"
      >
        <v-text-field
          v-model="filter.search"
          prepend-inner-icon="search"
          label="Buscar"
          single-line
          hide-details
          dark
          filled
        />
      </v-col>
      <!-- <v-col
        cols="12"
        md="3"
      >
        <v-switch
          v-model="filter.volume"
          label="Exibir Volume Médio"
          dense
          dark
          hide-details
          class="mt-0"
          @change="loadTabs"
        />
      </v-col> -->
      <v-col
        cols="12"
        md="3"
      >
        <v-switch
          v-model="filter.previous_month_avg"
          label="Calcular Média com mês anterior"
          dense
          dark
          hide-details
          class="mt-0"
          @change="onLoad"
        />
      </v-col>
      <v-col
        v-if="avg_text_show"
        cols="12"
        md="12"
      >
        <p>*Formula média ponderada, usada nas médias do mês e anterior: {{ avg_text }}</p>
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
      @change="onLoad"
    >
      <v-tab
        v-for="tabCurve in tabs"
        :key="tabCurve.id_meta_bpa"
      >
        {{ tabCurve.title }}
      </v-tab>

      <v-tab-item
        v-for="tabCurve in tabs"
        :key="tabCurve.id_meta_bpa"
        background-color="transparent"
        dark
      >
        <v-card
          color="transparent"
          dark
        >
          <v-card-title
            v-if="tabCurve.tipo != 'sem_classificar'"
          >
            {{ `Produtores : ${ tabCurve.items.length}` }}
            <v-spacer />
            {{ `${ tabCurve.tipo}` }} {{ `${ tabCurve.indicadores}` }}
          </v-card-title>
          <v-card-title
            v-else
          >
            {{ `Produtores: ${ tabCurve.items.length}` }}
          </v-card-title>
          <!-- <v-card-subtitle
            v-if="volumeMedioTotal > 0"
          >
            volume médio: {{ volumeMedioTotal }}
          </v-card-subtitle> -->
          <v-data-table
            :headers="tabCurve.headers"
            :items="tabCurve.items"
            :search="filter.search"
            class="elevation-1"
            :items-per-page="-1"
            hide-default-footer
          >
            <template #[`item.media_atual`]="{value}">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.media_atual_descricao`]="{value}">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>info</v-icon>
                  </span>
                </template>
                <span>
                  média mês formada por {{ value }}
                </span>
              </v-tooltip>
            </template>
            <template #[`item.media_anterior`]="{value}">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.media_anterior_descricao`]="{value}">
              <v-tooltip
                v-if="value"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >

                    <v-icon>info</v-icon>
                  </span>
                </template>
                <span>
                  média anterior formada por {{ value }}
                </span>
              </v-tooltip>
            </template>
            <template #[`item.media_arimetica`]="{value}">
              {{ formatNumber(value) }}
            </template>
            <template
              #[`item.ultima_visita`]="{ value }"
            >
              {{ formatDate(value, "DD/MM/YYYY") }}
            </template>
            <template
              #[`item.data_entrada_laticinio`]="{ value }"
            >
              {{ formatDate(value, "DD/MM/YYYY") }}
            </template>
            <template #[`item.status`]="{item}">
              <v-chip
                v-if="item.status.description == 'DESVINCULADO'"
                color="red"
                small
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      left
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>cancel</v-icon>
                    </v-avatar>
                    D
                  </template>
                  <span>
                    Saída em {{ formatDate(item.data_saida, 'DD/MM/YYYY') }}
                  </span>
                </v-tooltip>
              </v-chip>
              <v-chip
                v-else-if="item.status.description == 'SUSPENSO'"
                color="orange"
                small
              >
                <v-avatar
                  left
                  small
                >
                  <v-icon>remove_circle</v-icon>
                </v-avatar>
                S
              </v-chip>
              <v-chip
                v-else
                color="green"
                small
              >
                <v-avatar
                  left
                  small
                >
                  <v-icon>check_circle</v-icon>
                </v-avatar>
                A
              </v-chip>
            </template>
          </v-data-table>
          <v-card-title>
            <v-spacer />
          </v-card-title>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="blue darken-1"
        @click="gerarClassificacao()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              refresh
            </v-icon>
          </template>
          Gerar Classificação
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-overlay
      v-model="loading"
      relative
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import MonthPicker from "@/Support/Components/MonthPicker.vue";

import moment from "moment-timezone";
import XLSX from "xlsx-js-style";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import RatingService from "@/Domains/Projects/PQFL/BPA/Services/RatingService.js";
import  _ from "lodash";

const ratingService = new RatingService();

export default {
  name: "producer-rating",

  components: {

    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    MonthPicker,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    parseEmpty: (value) => !value ? '-' : value,
  },

  mixins: [ReportMixin],
  props: {
    view: String,
  },

  data() {
    return {
      loading: false,
      avg_text_show: false,
      avg_text: 'Mp = [(N1 x P1) + (N2 x P2) + (N3 x P3) + ... (Nx x Px)] / (P1 + P2 + P3 + ... Px)',

      filter: {
        project: {
          description: null,
          id: '',
        },
        curve: {
          value: '',
          text: ''
        },
        date: {
          input: "thisMonth",
          month: null,
        },
        technician: {
          id: "",
          description: ""
        },
        routes: [],

        volume: false,

        search: null,

        previous_month_avg: true,
      },
      months: [],

      tab: 0,

      projectList: [],

      producers: [],

      tabs: [],

      curveItem: [],

      ranges: [],

    };
  },

  created() {
    this.loadProject();
  },

  methods: {
    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onFilterDate([month]) {
      this.filter.date.month = month;
      this.onLoad();
    },
    onLoad() {
      if (!_.isEmpty(this.tabs)) {
        if (this.tabs[this.tab].tipo === 'sem_classificar') {
          this.loadProducerNotRating();

        } else if (this.tabs[this.tab].id_meta_bpa) {
          this.loadProducerRating();
        }
      }
    },

    async loadRanges() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/rangesBpa`,
          {
            id_assistencia: this.filter.project.id,
          }
        );

        let curve = [...new Set(data.flatMap(o => o.tipo))];
        this.curveItem = curve.map((c) => {
          return {
            text: ratingService.types[c],
            value: c,
          }
        });
        this.ranges = data;
        this.filter.curve = _.last(this.curveItem);
        this.loadTabs();

      } catch (error) {
        console.warn(error);
      }

    },

    async loadTabs() {
      this.tabs = [];
      this.avg_text_show = false;
      if (!this.filter.curve) {
        return false;
      }
      try {
        //monta aba com as faixas da curva escolhida
        const data = this.ranges.filter(c => c.tipo === this.filter.curve.value);

        this.tabs = data.map((c) => {
          const h = [
            { text: 'Código', value: 'codigo_laticinio' },
            { text: 'Nome', value: 'nome_produtor' },
            { text: 'Rota', value: 'rota_principal' },
            { text: 'Laticinio', value: 'nome_laticinio' },
            { text: 'Entrada', value: 'data_entrada_laticinio' },
            // { text: 'Última visita', value: 'ultima_visita' },
          ];

          if (this.filter.volume) {
            h.push({ text: 'Volume Médio', value: 'volume_media_diaria' });
          }

          let IndicadoresTitulo = '';
          if (c.indicador) {//curva simples
            h.push({ text: c.indicador, value: 'item.result0', align: 'center' });
            IndicadoresTitulo  = c.indicador;
          } else { //combinadas

            let metas_agrupadas = JSON.parse(c.metas_agrupadas || '') || [];
            let indicadores = [...new Set(metas_agrupadas.flatMap(o => o.indicator.indicator))];
            indicadores.forEach((i, k) => {
              h.push({ text: i, value: `item.result${k}`, align: 'center',  });
              // h.push({ text: `Data ${i}`, value: `item.resultDate${k}`, align: 'center',  });
              IndicadoresTitulo  = IndicadoresTitulo + ' [' + i + '] ';
            });
            h.push({ text: 'Média mês', value: 'media_atual', align: 'center'  });
            h.push({ text: '', value: 'media_atual_descricao', align: 'left', width: '5' });
            if (this.filter.previous_month_avg) {
              h.push({ text: 'Média Anterior', value: 'media_anterior', align: 'center'  });
              h.push({ text: '', value: 'media_anterior_descricao', align: 'left', width: '5'  });
              h.push({ text: 'Média ', value: 'media_arimetica', align: 'center'  });
            }

            this.avg_text_show = true;
          }

          h.push({ text: 'Status', value: 'status' });

          return {
            title: c.nome_faixa,
            id_meta_bpa: c.id_meta_bpa,
            tipo: ratingService.types[c.tipo],
            items: [],
            headers: h,
            indicadores: IndicadoresTitulo,
          }
        });

        this.tabs.push({
          title: 'Não Classificados',
          id_meta_bpa: Math.random(),
          tipo: 'sem_classificar',
          items: [],
          headers: [
            { text: 'Código', value: 'codigo_laticinio' },
            { text: 'Nome', value: 'nome_produtor' },
            { text: 'Rota', value: 'rota_principal' },
            { text: 'Laticinio', value: 'nome_laticinio' },
            { text: 'CCS', value: 'ccs_geometrica' },
            { text: 'CPP', value: 'cpp_geometrica' },
            { text: 'Status', value: 'status' },
          ],
        })

      } catch (error) {
        console.warn(error);
      }
    },

    async loadDateRange() {
      this.filter.min = moment(this.filter.project.dateStart).format('YYYY-MM');
      this.filter.max = moment(this.filter.project.dateEnd).format('YYYY-MM');
      this.filter.range = [moment(this.filter.project.dateStart).format('YYYY-MM'), moment().format('YYYY-MM')];
    },

    async loadProject() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "Dairy",
            view: 'rating'
          }
        );

        this.projectList = data.map((proj) => {
          return {
            text: proj.descricao,
            id: proj.id_assistencia,
          }
        });

        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];
          await this.loadDateRange();
          this.loadRanges();
          this.tab = 0;
        }

      } catch (error) {
        console.warn(error);
      }
    },

    async loadProducerRating() {
      try {

        this.loading = true;
        let id_meta_bpa   = this.tabs[this.tab].id_meta_bpa;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/listaProdutoresClassificadosLaticinio`,
          {
            mes_referencia: moment(this.filter.date.month).format('YYYY-MM'),
            id_faixa: id_meta_bpa,
            id_pessoa: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id),
            mostra_volume: this.filter.volume,
            media_mes_anterior: this.filter.previous_month_avg,
          }
        );

        const faixa = this.ranges.filter(c => c.tipo === this.filter.curve.value);
        let tipo = '';
        let tipoAgrupadas = [];
        if (_.first(faixa).condicional) {
          tipo =  _.first(faixa).condicional ;

        } else if (_.first(faixa).tipo == 'INCONFORME-PONTOS') {
          tipo = 'pontos';

        } else if (_.first(faixa).tipo == 'COMBINADAS') { //se combinada entao busco nas metas os tipos das combinadas e coloco no array de tipos
          const metas_agrupadas = JSON.parse(_.first(faixa).metas_agrupadas || '') || [];
          const ids = [...new Set(metas_agrupadas.flatMap(o => o.indicator))];
          const agrupadas = this.ranges.filter(array => ids.some(filter => filter.id === array.id_meta_bpa));
          tipoAgrupadas = agrupadas.map(a => {
            if (a.condicional) {
              return  a.condicional;
            } else if (a.tipo == 'INCONFORME-PONTOS') {
              return 'pontos';
            } else {
              return '';
            }
          });
        }

        this.producers = data.map(p => {
          let media_atual = '';
          let media_atual_descricao = '';
          let media_anterior = '';
          let media_anterior_descricao = '';
          let item = [];
          let texto_media = '';
          item.result0 = p.resultado ? this.formatValue(p.resultado, p.indicador) + ' ' + tipo  : '';
          if (p.tipo_faixa == 'COMBINADAS') {
            p.classificacao_produtor_agrupador.map((a, k) => {
              const head = this.tabs[this.tab].headers.find((h) => h.text === a.indicador);
              eval(`${head.value}= '${this.formatValue(a.resultado, a.indicador)} ${tipoAgrupadas[k]}'`);

              // const head2 = this.tabs[this.tab].headers.find((h) =>  h.text === `Data ${a.indicador}`);
              // eval(`${head2.value}= '${moment(a.data_referencia).format("MM/YYYY")}'`);
              if (a.peso) {
                texto_media += `${a.indicador}:( ${a.resultado} peso: ${a.peso} ) `;
              }
            })

            media_atual =  p.media_ponderada;
            media_atual_descricao = texto_media;

            if (p.media_ponderada_anterior > 0) {
              texto_media = '';
              p.classificacao_produtor_agrupador_anterior.map((a) => {
                //console.log(a);
                if (a.peso) {
                  texto_media += `${a.indicador}:( ${a.resultado} peso: ${a.peso} ) `;
                }
              })
              media_anterior = p.media_ponderada_anterior;
              media_anterior_descricao = texto_media;
            }

          }

          return {
            ...p,
            media_atual: media_atual,
            media_atual_descricao: media_atual_descricao,
            media_anterior: media_anterior,
            media_anterior_descricao: media_anterior_descricao,
            status: {
              description: p.status ? p.status : 'APROVADO',
              date_end: p.data_saida ? moment(p.data_saida, 'YYYY-MM-DD').format("DD/MMM/YY")  : '',
            },
            item: item,
          }
        });

        if (this.filter.previous_month_avg) {
          this.tabs[this.tab].items = _.orderBy( this.producers, 'media_arimetica', 'desc');
        } else {
          this.tabs[this.tab].items = _.orderBy( this.producers, 'media_ponderada', 'desc');
        }

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar produtores!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadProducerNotRating() {

      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/listaProdutoresNaoClassificadosBPA`,
          {
            mes_referencia: moment(this.filter.date.month).format('YYYY-MM'),
            tipo_faixa: this.filter.curve.value,
            data_inicio_projeto: this.filter.project.dateStart,
          }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }
        this.tabs[this.tab].items = data.map(p => {
          return {
            id_produtor: p.id_produtor,
            nome_produtor: p.nome,
            codigo_laticinio: p.codigo_laticinio,
            rota_principal: p.rota_principal || 'sem rota definida',
            status: {
              description: p.status ? p.status : 'APROVADO',
              date_end: p.data_saida ? moment(p.data_saida, 'YYYY-MM-DD').format("DD/MMM/YY")  : '',
            },
            cpp_geometrica: !_.isEmpty(p.medias) ? parseInt(_.first(p.medias).cbt_geometrica_mes) : "-",
            ccs_geometrica: !_.isEmpty(p.medias) ? parseInt(_.first(p.medias).ccs_geometrica_mes) : "-",
          }
        });

      } catch (error) {
        console.log(error);
        this.loading = false;

        this.$snotify.error("Oops, ocorreu ao listar não classificados!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async gerarClassificacao() {
      try {

        if (_.isEmpty(this.$store.state.settings.laticinio)) {
          this.$snotify.error("Informe o laticínio para gerar classificação", "Atenção");
          return false;
        }

        this.loading = true;

        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/classificarProdutorBPA`,
          {
            mes_referencia: moment(this.filter.date.month).format('YYYY-MM'),
            id_assistencia: this.filter.project.id,
            calcular_media_ponderada: true,
          }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }
        if (_.get(data, 'codigo') !== 1) {
          this.$snotify.error("Não foi possivel classificar produtores. Dados insuficientes!", "Atenção");
        }
        await this.onLoad();

      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$snotify.error("Oops, ocorreu um erro gerar classificação!", "Atenção");
      }
    },

    getReportJson() {
      let items = this.tabs[this.tab].items;
      const header = this.tabs[this.tab].headers;
      return items.map(o => {
        let initial = {};
        o.status = o.status.description;
        return header.reduce((acc, header) => ({
          ...acc,
          [header.text]: 'formatter' in header ? header.formatter(_.get(o, header.value, '')) : _.get(o, header.value, ''),
        }), initial);
      });

    },

    print() {
      const title = (this.tabs[this.tab].title).substr(1, 30);

      return this.printFromJson(this.getReportJson(), title, true);
    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcel() {
      const title = this.tabs[this.tab].title;
      let data = XLSX.utils.json_to_sheet(this.getReportJson());
      const filename = (`Visitas ${title}`).substr(1, 30);

      const workbook = this.XLSX.utils.book_new();
      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    formatValue(value, indicator) {
      if (indicator === 'CCS' || indicator === 'CPP' || indicator === 'CPP trimestral' || indicator === 'CCS Trimestral' ) {
        return parseInt(value);
      } else {
        return this.formatNumber(value);
      }

    },

    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
    formatNumber: (value) => !value ? "-" : new Intl.NumberFormat('pt-BR').format(value) ,
  },
};
</script>
