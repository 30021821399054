<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Controle de Estoque por Depósito
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-card
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-date-range-picker
                  v-model="filter.dateRange"
                  :ranges="dateRanges"
                  label="Período Fabricação"
                  dark
                  @change="loadProducts"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                class="pt-0"
              >
                <v-select
                  v-model="filter.type"
                  label="Tipo Estoque"
                  :items="[
                    { value: 'TODOS', text: 'Todos' },
                    { value: 'DISPONIVEL', text: 'Disponível' },
                    { value: 'INDISPONIVEL', text: 'Indisponível' },
                  ]"
                  dark
                  filled
                  hide-details
                  clearable
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="pt-0"
              >
                <v-text-field
                  v-model="filter.search"
                  append-icon="search"
                  label="Buscar"
                  filled
                  single-line
                  hide-details
                  dark
                />
              </v-col>
            </v-row>
          </v-card-title>
          <data-table
            ref="report"
            :headers="headers"
            :items="filteredItems"
            dark
            group-by="productName"
          >
            <template #[`group.header`]="{ group, isOpen, toggle, headers, items }">
              <td
                v-for="(h, idx) in headers"
                :key="h.value"
                :class="h.align ? `text-${h.align}` : 'text-start'"
              >
                <template v-if="h.value === 'data-table-expand'">
                  <v-btn
                    icon
                    @click="toggle"
                  >
                    <v-icon>
                      {{ isOpen ? 'remove' : 'add' }}
                    </v-icon>
                  </v-btn>
                </template>
                <template v-else-if="idx === 1">
                  <b v-if="group">{{ group }}</b>
                </template>
                <template v-else-if="h.value === 'manufactured'">
                  <v-chip
                    small
                  >
                    {{ formatNumber(totals[group]?.manufactured) }}
                    <span class="ml-1">
                      {{ items[0].product.measurement }}
                    </span>
                  </v-chip>
                </template>
                <template v-else-if="h.value === 'quantity'">
                  <v-chip
                    small
                    :color="getQuantityColor(totals[group]?.quantity)"
                  >
                    {{ formatNumber(totals[group]?.quantity) }}
                    <span class="ml-1">
                      {{ items[0].product.measurement }}
                    </span>
                  </v-chip>
                </template>
                <template v-else-if="h.value === 'packagedQuantity'">
                  <v-chip
                    small
                    :color="getQuantityColor(totals[group]?.packagedQuantity)"
                  >
                    {{ formatNumber(totals[group]?.packagedQuantity) }}
                    <span class="ml-1">
                      {{ items[0].product.measurement }}
                    </span>
                  </v-chip>
                </template>
                <template v-else-if="h.value === 'packagedGrossWeight'">
                  <v-chip
                    small
                    color="blue darken-2"
                  >
                    {{ formatNumber(totals[group]?.packagedGrossWeight) }}
                    <span class="ml-1">
                      Kg
                    </span>
                  </v-chip>
                </template>
                <template v-else-if="h.value === 'shippedQuantity'">
                  <v-chip
                    small
                    :color="getQuantityColor(totals[group]?.shippedQuantity)"
                  >
                    {{ formatNumber(totals[group]?.shippedQuantity) }}
                    <span class="ml-1">
                      {{ items[0].product.measurement }}
                    </span>
                  </v-chip>
                </template>
                <template v-else-if="h.value === 'shippedGrossWeight'">
                  <v-chip
                    small
                    color="blue darken-2"
                  >
                    {{ formatNumber(totals[group]?.shippedGrossWeight) }}
                    <span class="ml-1">
                      Kg
                    </span>
                  </v-chip>
                </template>
                <template v-else-if="warehouseHeaderIds.includes(h.value)">
                  <v-chip
                    small
                  >
                    {{ formatNumber(totals[group]?.[h.value]) }}
                    <span class="ml-1">
                      {{ items[0].product.measurement }}
                    </span>
                  </v-chip>
                </template>
              </td>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script setup>
import { ref, computed, reactive } from 'vue'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'

import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue'

const { progressBar, notify } = useUtils()

const dateRanges = {
  'Hoje': [
    moment().startOf('day').format('YYYY-MM-DD'),
    moment().endOf('day').format('YYYY-MM-DD')
  ],
  'Ontem': [
    moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
    moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')
  ],
  'Este Mês': [
    moment().startOf('month').format('YYYY-MM-DD'),
    moment().endOf('month').format('YYYY-MM-DD')
  ],
  'Mês Anterior': [
    moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
    moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
  ],
  'Todos': [null, null]
}

const filter = reactive({
  dateRange: dateRanges['Este Mês'],
  type: 'DISPONIVEL',
  search: null
})

const items = ref([])

const warehouseHeaders = ref([])

const warehouseHeaderIds = computed(() => warehouseHeaders.value.map(h => h.value))

const headers = computed(() => [
  { text: 'Produto', value: 'productName' },
  { text: 'Lote', value: 'lotNumber' },
  { text: 'Fabricação', value: 'manufacturingDate', align: 'center', formatter: value => formatDate(value, 'DD/MM/YYYY') },
  { text: 'Validade', value: 'expirationDate', align: 'center', formatter: value => formatDate(value, 'DD/MM/YYYY') },
  { text: 'Produzido', value: 'manufactured', formatter: value => formatNumber(value), mask: '#,##0.0' },
  { text: 'Estoque Geral', value: 'quantity', formatter: value => formatNumber(value), mask: '#,##0.0' },
  { text: 'Estoque Encaixotado', value: 'packagedQuantity', formatter: value => formatNumber(value), mask: '#,##0.0' },
  { text: 'Peso Encaixotado', value: 'packagedGrossWeight', formatter: value => formatNumber(value) + ' Kg', mask: '#,##0.0' },
  { text: 'Estoque Expedido', value: 'shippedQuantity', formatter: value => formatNumber(value), mask: '#,##0.0' },
  { text: 'Peso Expedido', value: 'shippedGrossWeight', formatter: value => formatNumber(value) + ' Kg', mask: '#,##0.0' },
  ...warehouseHeaders.value,
])

const report = ref()
const totals = ref({})

const filteredItems = computed(() => {
  const type = filter.type === 'TODOS' ? null : filter.type
  if (!filter.search && !type) {
    return items.value
  }

  const search = filter.search?.toUpperCase()?.trim()

  return items.value.filter(item => {
    const matchSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search)
    const matchType = !type || (type === 'DISPONIVEL' && item.manufactured > item.shippedQuantity) || (type === 'INDISPONIVEL' && item.manufactured < item.shippedQuantity)

    return matchSearch && matchType
  })
})

const loadProducts = async () => {
  try {
    progressBar?.loading()

    const [startDate, endDate] = filter.dateRange

    const { data } = await axios.get(`/stock`, { params: {
      apenas_lotes_industria: 1,
      agrupar_por_lote: 1,
      data_inicio: startDate,
      data_fim: endDate
    } })

    items.value = data
      .filter(stock => !!stock.item)
      .map(stock => ({
        productName: `${stock.item.cod_item} - ${stock.item.nome}`,
        product: {
          id: stock.item.id_item,
          code: stock.item.cod_item,
          name: stock.item.nome,
          measurement: stock.item.unidade,
          grossWeight: stock.item.peso_bruto,
        },
        lotNumber: stock.numero_lote,
        lotStatus: stock.status_lote,
        manufacturingDate: stock.data_fabricacao,
        expirationDate: stock.data_validade,
        manufactured: stock.produzido,
        quantity: stock.quantidade,
        palletizedQuantity: parseFloat(stock.quantidade_paletizado) || 0,
        notPalletizedQuantity: stock.quantidade_paletizado < stock.quantidade ? stock.quantidade - (parseFloat(stock.quantidade_paletizado) || 0) : 0,
        packagedQuantity: parseFloat(stock.quantidade_embalado) || 0,
        notPackagedQuantity: stock.quantidade_embalado < stock.quantidade ? stock.quantidade - (parseFloat(stock.quantidade_embalado) || 0) : 0,
        grossWeight: stock.quantidade > 0 && stock.item.peso_bruto ? (stock.quantidade * stock.item.peso_bruto) : null,
        packagedGrossWeight: parseFloat(stock.peso_bruto_embalado) || 0,
        shippedQuantity: parseFloat(stock.quantidade_expedido) || 0,
        shippedGrossWeight: parseFloat(stock.peso_bruto_expedido) || 0,
        ...Object.keys(stock.depositos).reduce((acc, key) => ({
          ...acc,
          [key]: stock.depositos[key].quantidade,
        }), {})
      }))

    const group = groupBy(items.value, 'productName')

    const warehouses = data[0]?.depositos || {}

    warehouseHeaders.value = Object.keys(warehouses).map(value => ({
      text: `Estoque ${warehouses[value].descricao}`,
      value,
      formatter: value => formatNumber(value),
      mask: '#,##0.0',
    }))

    totals.value = mapValues(group, (items) => {
      return {
        manufactured: items.filter(o => o.manufactured).reduce((acc, cur) => acc + cur.manufactured, 0),
        quantity: items.filter(o => o.quantity).reduce((acc, cur) => acc + cur.quantity, 0),
        palletizedQuantity: items.filter(o => o.palletizedQuantity).reduce((acc, cur) => acc + cur.palletizedQuantity, 0),
        notPalletizedQuantity: items.filter(o => o.notPalletizedQuantity).reduce((acc, cur) => acc + cur.notPalletizedQuantity, 0),
        packagedQuantity: items.filter(o => o.packagedQuantity).reduce((acc, cur) => acc + cur.packagedQuantity, 0),
        notPackagedQuantity: items.filter(o => o.notPackagedQuantity).reduce((acc, cur) => acc + cur.notPackagedQuantity, 0),
        grossWeight: items.filter(o => o.grossWeight).reduce((acc, cur) => acc + cur.grossWeight, 0),
        packagedGrossWeight: items.filter(o => o.packagedGrossWeight).reduce((acc, cur) => acc + cur.packagedGrossWeight, 0),
        shippedQuantity: items.filter(o => o.shippedQuantity).reduce((acc, cur) => acc + cur.shippedQuantity, 0),
        shippedGrossWeight: items.filter(o => o.shippedGrossWeight).reduce((acc, cur) => acc + cur.shippedGrossWeight, 0),
        ...Object.keys(warehouses).reduce((acc, key) => ({
          ...acc,
          [key]: items.filter(o => o[key]).reduce((acc, cur) => acc + cur[key], 0),
        }), {})
      }
    })
  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao carregar estoque'
    notify.error(message, 'Atenção')
    console.warn(error)
  } finally {
    progressBar?.hide()
  }
}

const exportExcel = () => {
  report.value?.exportExcel(null, 'Controle de Estoque por Lote')
}

const print = () => {
  report.value?.print(null, 'Controle de Estoque por Lote')
}

const getQuantityColor = (value) => {
  if (value < 0) {
    return 'red darken-1'
  }
  if (value > 0) {
    return 'green darken-1'
  }
  return 'grey'
}

const formatDate = (value, format) => !value ? '-' : moment(value).format(format)
const formatNumber = (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value)

loadProducts()
</script>
