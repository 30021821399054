
import reportMixin from '@/Support/Mixins/ReportMixin.js'

/**
 * Composable a partir do mixin (aka Gambi 😶)
 * Para ser usado com script setup
 * Refatorar futuramente quando todo o app estiver usando Vue3? 🔪
 */
export function useReports() {
  const { methods, data } = reportMixin

  const reports = {}

  Object.assign(reports, data())

  Object.keys(methods).forEach(methodName => {
    reports[methodName] = methods[methodName].bind(reports)
  })

  return reports
}
